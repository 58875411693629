import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';

const Steps = ({ activeStep, className }) => {
    const validatedActiveStep = Math.max(1, Math.min(activeStep, 3)) - 1;

    return (
        <Stepper
            activeStep={validatedActiveStep}
            alternativeLabel
            className={className}
        >
            <Step>
                <StepLabel>Memorial Details</StepLabel>
            </Step>
            <Step>
                <StepLabel>Write Obituary</StepLabel>
            </Step>
            <Step>
                <StepLabel>Design Memorial</StepLabel>
            </Step>
        </Stepper>
    );
};

Steps.propTypes = {
    activeStep: PropTypes.number.isRequired,
    className: PropTypes.string, // Added prop for custom className
};

export default Steps;
