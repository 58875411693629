import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Image_gallery() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [images, setImages] = useState([]);
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');

    useEffect(() => {
        const fetchImages = async () => {
            const { data, error } = await supabase
                .from('memorials')
                .select('media, deceased_name, deceased_nickname, deceased_surname')
                .eq('owner_id', userId)
                .eq('memorial_Id', memorialId);

            if (error) {
                console.error('Error fetching images:', error.message);
                return;
            }

            if (data && data.length > 0) {
                const imageFiles = data[0].media?.filter(file => ['jpg', 'png', 'gif'].includes(file.type)) || [];
                setImages(imageFiles.map(image => ({ original: image.url, thumbnail: image.url })));
                setDeceasedName(data[0].deceased_name);
                setDeceasedNickname(data[0].deceased_nickname);
                setDeceasedSurname(data[0].deceased_surname);
            }
        };

        fetchImages();
    }, [userId, memorialId]);

    return (
        <div>
            <div style={{ backgroundImage: `url('/images/background1.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <div style={{ width: '85%', height: '85%', margin: '50px', backgroundColor: 'rgba(255, 255, 255, 0.95)', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                    <h1>Photos of {deceasedName} "{deceasedNickname}" {deceasedSurname}</h1>
                    <br></br>
                    <ImageGallery items={images} />
                </div>
            </div>
        </div>
    );
}

export default Image_gallery;
