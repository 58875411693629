import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import Navbar_public from './Navbar_public';

const Privacy_policy = () => {
  return (
    <div>
      <Navbar_public />
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center">
            Effective Date: [02/10/2024]
          </Typography> <br/>
          <Typography variant="body1" paragraph align="left">
            At forever.org.za, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your personal data when you interact with our website.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We may collect and process the following personal information:
            <ul>
              <li>Personal Identification Information: Name, email address, phone number, and other contact details.</li>
              <li>Usage Data: Information on how you interact with our website, including your IP address, browser type, and device information.</li>
              <li>Cookies and Tracking Data: We use cookies and similar tracking technologies to enhance your experience on our website.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We use the personal information we collect for the following purposes:
            <ul>
              <li>To provide and maintain our website.</li>
              <li>To process your registration or account requests.</li>
              <li>To communicate with you regarding services, updates, or promotional offers.</li>
              <li>To improve our website, products, and services.</li>
              <li>To comply with legal obligations and resolve any disputes.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            3. Sharing Your Information
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We do not sell, trade, or otherwise transfer your personal information to outside parties, except:
            <ul>
              <li>To trusted third-party service providers who assist us in operating our website or conducting our business, provided that they agree to keep this information confidential.</li>
              <li>When required by law or to protect our rights, safety, or the rights of others.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            4. Cookies and Tracking Technologies
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We use cookies and similar technologies to track the activity on our website and hold certain information. You can instruct your browser to refuse cookies or notify you when a cookie is being sent. However, some website features may not function properly without cookies.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            5. Data Security
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We take appropriate security measures to protect your personal information against unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            6. Your Rights
          </Typography>
          <Typography variant="body1" paragraph align="left">
            You have the right to:
            <ul>
              <li>Access and receive a copy of your personal data.</li>
              <li>Correct or update inaccurate personal information.</li>
              <li>Request the deletion of your personal data, subject to legal and regulatory requirements.</li>
              <li>Object to the processing of your data under certain conditions.</li>
            </ul>
            To exercise these rights, please contact us at [Insert Contact Email].
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            7. Third-Party Links
          </Typography>
          <Typography variant="body1" paragraph align="left">
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these sites. We encourage you to read the privacy policies of any third-party websites you visit.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            8. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and the updated Privacy Policy will take effect as soon as it is posted.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            9. Contact Us
          </Typography>
          <Typography variant="body1" paragraph align="left">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            <ul>
              <li>Forever Memorials Email: info@forever.org.za</li>
              <li>Website: <a href="https://www.forever.org.za">https://www.forever.org.za</a></li>
            </ul>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Privacy_policy;
