import React, { useRef, useEffect, useState } from 'react';
import { Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';

const Canvas_text = ({
    id,
    x,
    y,
    scaleX,
    scaleY,
    text,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    textDecoration,
    fill,
    isSelected,
    onSelect,
    onDelete,
    onDuplicate,
    onBringForward,
    onSendBackward,
}) => {
    const textNodeRef = useRef();
    const trRef = useRef();
    const toolbarRef = useRef(); // Ref for the toolbar
    const [editing, setEditing] = useState(false);
    const [inputValue, setInputValue] = useState(text);
    const [textProps, setTextProps] = useState({
        fontFamily,
        fontSize,
        fontStyle,
        fontWeight,
        textDecoration: textDecoration || '',
        fill,
    });
    const [textSize, setTextSize] = useState({
        width: 0,
        height: 0,
        top: 0,
        left: 0,
    });

    const webFonts = [
        'Arial',
        'Verdana',
        'Helvetica',
        'Times New Roman',
        'Tahoma',
        'Trebuchet',
        'Georgia',
        'Garamond',
        'Courier New',
        'Brush Script MT',
    ];

    useEffect(() => {
        if (textNodeRef.current) {
            const rect = textNodeRef.current.getClientRect({ relativeTo: textNodeRef.current.getLayer() });
            setTextSize({
                width: rect.width,
                height: rect.height,
                top: rect.y,
                left: rect.x,
            });
        }
    }, [x, y, scaleX, scaleY, text, textProps]);

    useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([textNodeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const applyChanges = () => {
        textNodeRef.current.text(inputValue);
        textNodeRef.current.setAttrs(textProps);
        textNodeRef.current.getLayer().batchDraw();
        setEditing(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                textNodeRef.current &&
                toolbarRef.current &&
                !textNodeRef.current.getStage().container().contains(event.target) &&
                !toolbarRef.current.contains(event.target)
            ) {
                applyChanges();
                setEditing(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [textNodeRef, toolbarRef, applyChanges]);

    const handleDblClick = () => {
        setEditing(true);
        setInputValue(textNodeRef.current.text());
        setTextProps({
            fontFamily: textNodeRef.current.attrs.fontFamily,
            fontSize: textNodeRef.current.attrs.fontSize,
            fontStyle: textNodeRef.current.attrs.fontStyle,
            fontWeight: textNodeRef.current.attrs.fontWeight,
            textDecoration: textNodeRef.current.attrs.textDecoration || '',
            fill: textNodeRef.current.attrs.fill,
        });
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            applyChanges();
        }
    };

    const handleFormatChange = (property, value) => {
        setTextProps((prevProps) => ({
            ...prevProps,
            [property]: value,
        }));
    };

    const handleTextChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleRightClick = (event) => {
        event.evt.preventDefault();
        const menu = document.getElementById('context-menu');
        menu.style.top = `${event.evt.clientY}px`;
        menu.style.left = `${event.evt.clientX}px`;
        menu.style.display = 'block';

        document.getElementById('duplicate').onclick = () => {
            onDuplicate();
            menu.style.display = 'none';
        };
        document.getElementById('delete').onclick = () => {
            onDelete();
            menu.style.display = 'none';
        };
        document.getElementById('bringForward').onclick = () => {
            onBringForward();
            menu.style.display = 'none';
        };
        document.getElementById('sendBackward').onclick = () => {
            onSendBackward();
            menu.style.display = 'none';
        };
    };

    const toggleBold = () => {
        const newWeight = textProps.fontWeight === 'bold' ? 'normal' : 'bold';
        handleFormatChange('fontWeight', newWeight);
    };

    const toggleItalic = () => {
        const newStyle = textProps.fontStyle === 'italic' ? 'normal' : 'italic';
        handleFormatChange('fontStyle', newStyle);
    };

    const toggleUnderline = () => {
        const newDecoration = textProps.textDecoration.includes('underline') ? textProps.textDecoration.replace('underline', '') : `${textProps.textDecoration || ''} underline`;
        handleFormatChange('textDecoration', newDecoration);
    };

    const toggleStrikethrough = () => {
        const newDecoration = textProps.textDecoration.includes('line-through') ? textProps.textDecoration.replace('line-through', '') : `${textProps.textDecoration || ''} line-through`;
        handleFormatChange('textDecoration', newDecoration);
    };

    const handleColorChange = (color) => {
        handleFormatChange('fill', color);
    };

    const handleToolbarClick = (e) => {
        e.stopPropagation();
    };

    return (
        <React.Fragment>
            <Text
                id={id}
                x={x}
                y={y}
                scaleX={scaleX}
                scaleY={scaleY}
                text={text}
                ref={textNodeRef}
                draggable
                onContextMenu={handleRightClick}
                onDblClick={handleDblClick}
                onTap={onSelect}
                onClick={onSelect}
                opacity={editing ? 0 : 1}
                {...textProps}
                fontStyle={textProps.fontStyle}
                fontWeight={textProps.fontWeight}
                textDecoration={textProps.textDecoration}
            />

            {isSelected && <Transformer ref={trRef} />}

            {editing && (
                <Html>
                    <div
                        style={{
                            position: 'absolute',
                            top: textSize.top,
                            left: textSize.left,
                            width: textSize.width,
                            height: textSize.height,
                            overflow: 'hidden',
                            pointerEvents: 'none',
                        }}
                    >
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleTextChange}
                            onKeyPress={handleKeyPress}
                            style={{
                                width: '100%',
                                height: '100%',
                                fontSize: `${textSize.height}px`, // Use textSize.height as fontSize
                                fontFamily: textProps.fontFamily,
                                fontWeight: textProps.fontWeight,
                                fontStyle: textProps.fontStyle,
                                textDecoration: textProps.textDecoration,
                                color: textProps.fill,
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                padding: 0,
                                margin: 0,
                                boxSizing: 'border-box',
                                pointerEvents: 'all',
                            }}
                            autoFocus
                        />
                    </div>
                    <div
                        ref={toolbarRef} // Attach the ref to the toolbar
                        style={{
                            position: 'absolute',
                            top: `${textSize.top + textSize.height + 10}px`,
                            left: `${textSize.left}px`,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: 10,
                            backgroundColor: 'white',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '8px',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        }}
                        onClick={handleToolbarClick}
                    >
                        <label style={{ marginRight: '8px' }}>
                            Font Size:
                            <input
                                type="number"
                                value={textProps.fontSize}
                                onChange={(e) => handleFormatChange('fontSize', parseInt(e.target.value))}
                                style={{
                                    width: '50px',
                                    marginLeft: '4px',
                                    padding: '2px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </label>
                        <label style={{ marginRight: '8px' }}>
                            Font Family:
                            <select
                                value={textProps.fontFamily}
                                onChange={(e) => handleFormatChange('fontFamily', e.target.value)}
                                style={{
                                    marginLeft: '4px',
                                    padding: '2px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                }}
                            >
                                {webFonts.map((font) => (
                                    <option key={font} value={font}>
                                        {font}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <button
                            onClick={toggleBold}
                            style={{
                                fontWeight: textProps.fontWeight === 'bold' ? 'bold' : 'normal',
                                marginRight: '8px',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                backgroundColor: textProps.fontWeight === 'bold' ? '#ddd' : 'white',
                            }}
                        >
                            B
                        </button>
                        <button
                            onClick={toggleItalic}
                            style={{
                                fontStyle: textProps.fontStyle === 'italic' ? 'italic' : 'normal',
                                marginRight: '8px',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                backgroundColor: textProps.fontStyle === 'italic' ? '#ddd' : 'white',
                            }}
                        >
                            I
                        </button>
                        <button
                            onClick={toggleUnderline}
                            style={{
                                textDecoration: textProps.textDecoration.includes('underline') ? 'underline' : 'none',
                                marginRight: '8px',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                backgroundColor: textProps.textDecoration.includes('underline') ? '#ddd' : 'white',
                            }}
                        >
                            U
                        </button>
                        <button
                            onClick={toggleStrikethrough}
                            style={{
                                textDecoration: textProps.textDecoration.includes('line-through') ? 'line-through' : 'none',
                                marginRight: '8px',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                backgroundColor: textProps.textDecoration.includes('line-through') ? '#ddd' : 'white',
                            }}
                        >
                            S
                        </button>
                        <label style={{ marginRight: '8px' }}>
                            Color:
                            <input
                                type="color"
                                value={textProps.fill}
                                onChange={(e) => handleColorChange(e.target.value)}
                                style={{
                                    marginLeft: '4px',
                                    padding: '2px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </label>
                        <button
                            onClick={applyChanges}
                            style={{
                                padding: '4px 8px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                cursor: 'pointer',
                            }}
                        >
                            Apply
                        </button>
                    </div>
                </Html>
            )}
        </React.Fragment>
    );
};

export default Canvas_text;
