import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/system';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const Image = styled('img')({
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '8px',
});

async function fetchUserName(userId) {
    let { data, error } = await supabase
        .from('Users')
        .select('name, surname')
        .eq('id', userId)
        .single();

    if (error) {
        console.error(error);
        return null;
    }

    return `${data.name} ${data.surname}`;
}

// Function to validate if a media item is an image
const isValidImage = (mediaItem) => {
    const validImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
    return mediaItem && validImageExtensions.some(extension => mediaItem.url.toLowerCase().endsWith(extension));
};

// Function to find the first valid image in the media array
const getFirstValidImage = (media) => {
    if (!media || media.length === 0) return null;
    return media.find(isValidImage);
};

function My_memorials() {
    const [memorials, setMemorials] = useState([]);
    const userId = Cookies.get('userId');
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [currentShareUrl, setCurrentShareUrl] = useState('');
    const [copyConfirmation, setCopyConfirmation] = useState(false); // Confirmation message state
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        fetchUserName(userId).then(setUserName);
    }, [userId]);

    useEffect(() => {
        const fetchMyMemorials = async () => {
            setIsLoading(true);
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('*')
                    .eq('owner_id', userId);

                if (error) {
                    throw error;
                }

                if (data) {
                    setMemorials(data.map((item, i) => ({
                        ...item,
                        id: i + 1,
                        created_on: new Date(item.created_at).toLocaleDateString(),
                        published: item.published ? 'Yes' : 'No',
                    })));
                }
            } catch (error) {
                console.error('Error fetching my memorials:', error.message);
            }
            setIsLoading(false);
        };

        if (userId) {
            fetchMyMemorials();
        }
    }, [userId]);

    const handleShareClick = (memorialId) => {
        const baseUrl = window.location.origin;
        const shareUrl = `${baseUrl}/View_memorial/${memorialId}`;
        setCurrentShareUrl(shareUrl);
        setOpenShareModal(true);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(currentShareUrl);
        setCopyConfirmation(true);
        setTimeout(() => setCopyConfirmation(false), 2000); // Remove confirmation after 2 seconds
    };

    const handleEditMemorial = (memorialId) => {
        Cookies.set('memorialId', memorialId);
        navigate('/Edit_memorial2');
    };

    const handleViewMemorial = (memorialId) => {
        Cookies.set('memorialId', memorialId);
        navigate('/Memorial');
    };

    const handleDeleteMemorial = async (memorialId) => {
        try {
            const { error } = await supabase
                .from('memorials')
                .delete()
                .eq('memorial_Id', memorialId);

            if (error) {
                console.error('Error deleting memorial:', error.message);
                return;
            }

            setMemorials((prev) => prev.filter((memorial) => memorial.memorial_Id !== memorialId));
            console.log('Memorial deleted successfully');
        } catch (err) {
            console.error('Unexpected error:', err);
        }
    };

    const handleTogglePublished = async (memorialId, currentStatus) => {
        const newStatus = currentStatus === 'Yes' ? false : true;
        try {
            const { error } = await supabase
                .from('memorials')
                .update({ published: newStatus })
                .eq('memorial_Id', memorialId);

            if (error) {
                console.error('Error updating published status:', error.message);
                return;
            }

            setMemorials((prev) =>
                prev.map((memorial) =>
                    memorial.memorial_Id === memorialId
                        ? { ...memorial, published: newStatus ? 'Yes' : 'No' }
                        : memorial
                )
            );
        } catch (err) {
            console.error('Unexpected error:', err);
        }
    };

    const renderCards = () => {
        return memorials.map((memorial, index) => {
            const validImage = getFirstValidImage(memorial.media);
            return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ mb: 2, textAlign: 'center', padding: isMobile ? '10px' : '16px' }}>
                        {validImage ? (
                            <Image src={validImage.url} alt="Memorial" />
                        ) : (
                            <Image src="/images/thumbnail.svg" alt="Default Thumbnail" />
                        )}
                        <CardContent>
                            <Typography variant="h6">
                                {memorial.deceased_name} {memorial.deceased_surname}
                            </Typography>
                            <Typography variant="body2">Created On: {memorial.created_on}</Typography>
                            <Typography variant="body2">
                                Date of Birth: {memorial.deceased_date_of_birth}
                            </Typography>
                            <Typography variant="body2">
                                Date of Death: {memorial.deceased_date_of_death}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    gap: 1,
                                    mt: 2,
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="body2">Published:</Typography>
                                <Switch
                                    checked={memorial.published === 'Yes'}
                                    onChange={() =>
                                        handleTogglePublished(memorial.memorial_Id, memorial.published)
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                        </CardContent>
                        <CardActions
                            sx={{
                                display: 'flex',
                                flexWrap: isMobile ? 'wrap' : 'nowrap',
                                justifyContent: isMobile ? 'space-evenly' : 'space-between',
                                gap: isMobile ? 1 : 2,
                                flexDirection: 'row',
                            }}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#1976d2',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#115293' },
                                }}
                                onClick={() => handleEditMemorial(memorial.memorial_Id)}
                            >
                                Edit
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#6a0dad',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#4b0082' },
                                }}
                                onClick={() => handleViewMemorial(memorial.memorial_Id)}
                            >
                                View
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#ff9800',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#f57c00' },
                                }}
                                onClick={() => handleShareClick(memorial.memorial_Id)}
                            >
                                Share
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#d32f2f',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#9a0007' },
                                    mt: isMobile ? 1 : 0, // Add margin for mobile row break
                                }}
                                onClick={() => handleDeleteMemorial(memorial.memorial_Id)}
                            >
                                Delete
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            );
        });
    };

    return (
        <div>
            <Navbar />
            <div
                style={{
                    backgroundImage: `url('/images/background1.jpg')`,
                    backgroundSize: 'cover',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: '85%',
                        margin: '50px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(10px)',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '10px',
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h4"
                        gutterBottom
                        style={{ marginBottom: '20px' }}
                    >
                        {userName}'s Memorials
                    </Typography>
                    <Grid container spacing={2}>
                        {renderCards()}
                    </Grid>
                </div>
            </div>
            <Modal
                open={openShareModal}
                onClose={() => setOpenShareModal(false)}
                aria-labelledby="share-modal"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '300px',
                        bgcolor: 'background.paper',
                        p: 4,
                        boxShadow: 24,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="h6" textAlign="center">
                        Share Memorial
                    </Typography>
                    <Typography
                        variant="body1"
                        mt={2}
                        sx={{
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {currentShareUrl}
                    </Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={handleCopyLink}
                    >
                        Copy Link
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        onClick={() => setOpenShareModal(false)}
                    >
                        Close
                    </Button>
                    {copyConfirmation && (
                        <Typography
                            variant="body2"
                            sx={{
                                mt: 1,
                                color: 'green',
                                textAlign: 'center',
                            }}
                        >
                            Link copied to clipboard!
                        </Typography>
                    )}
                </Box>
            </Modal>
        </div>
    );
}

export default My_memorials;
