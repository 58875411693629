import React, { useState, useEffect, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    IconButton,
    CircularProgress,
    Tabs,
    Tab,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import PublishIcon from '@mui/icons-material/Publish';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);




function Music() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [playingFile, setPlayingFile] = useState(null);
    const [audioFiles, setAudioFiles] = useState([]);
    const audioRef = useRef(null);


    useEffect(() => {
        const fetchAudioFiles = async () => {
            try {
                const response = await fetch('https://forever.org.za/music_loader.php');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setAudioFiles(data);
            } catch (error) {
                console.error("Failed to fetch audio files:", error);
            }
        };
    
        fetchAudioFiles();
    }, []);

    useEffect(() => {
        if (userId && memorialId) {
            updateFilesInDirectory();
        }
    }, [userId, memorialId]);

    const onDrop = React.useCallback((acceptedFiles) => {
        acceptedFiles.forEach(file => handleFileUpload(file));
    }, [userId, memorialId]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'audio/*',
        maxSize: 50242880, // 50MB
    });

    const handleFileUpload = async (file) => {
        if (!userId || !memorialId) {
            console.error('Missing userId or memorialId cookies');
            return;
        }
        setLoading(true);

        const bucketName = 'memorial_music';

        const { data, error } = await supabase.storage
            .from(bucketName)
            .upload(`public/${userId}/${memorialId}/${file.name}`, file, { upsert: true });

        setLoading(false);

        if (error) {
            console.error('Error uploading file:', error.message);
            return;
        }

        await updateFilesInDirectory();
    };

    const handleFileDelete = async (fileName) => {
        setLoading(true);

        const isLibraryFile = audioFiles.includes(fileName);

        if (isLibraryFile) {
            setUploadedFiles(prevFiles => {
                const updatedFiles = prevFiles.filter(file => file.name !== fileName);
                updateDatabase(updatedFiles);
                return updatedFiles;
            });
        } else {
            const bucketName = 'memorial_music';
            const { data, error } = await supabase.storage
                .from(bucketName)
                .remove([`public/${userId}/${memorialId}/${fileName}`]);

            if (error) {
                console.error('Error deleting file:', error.message);
            } else {
                setUploadedFiles(prevFiles => {
                    const updatedFiles = prevFiles.filter(file => file.name !== fileName);
                    updateDatabase(updatedFiles);
                    return updatedFiles;
                });
            }
        }

        setLoading(false);
    };

    const updateDatabase = async (updatedFiles) => {
        try {
            const { data, error } = await supabase
                .from('memorials')
                .update({ music: updatedFiles })
                .eq('owner_id', userId)
                .eq('memorial_Id', memorialId);

            if (error) {
                throw error;
            }

            console.log('Music URLs updated successfully');
        } catch (error) {
            console.error('Error updating music URLs:', error.message);
        }
    };

    const updateFilesInDirectory = async () => {
        const bucketName = 'memorial_music';

        const { data: fileList, error: listError } = await supabase.storage
            .from(bucketName)
            .list(`public/${userId}/${memorialId}`);

        if (listError) {
            console.error('Error listing files:', listError.message);
            return;
        }

        const { data: memorialData, error: dbError } = await supabase
            .from('memorials')
            .select('music')
            .eq('owner_id', userId)
            .eq('memorial_Id', memorialId)
            .single();

        if (dbError) {
            console.error('Error fetching memorial data:', dbError.message);
            return;
        }

        const storageFiles = fileList
            .filter(file => ['mp3', 'wav'].includes(file.name.split('.').pop()))
            .map(file => ({
                name: file.name,
                url: `${supabaseUrl}/storage/v1/object/public/${bucketName}/public/${userId}/${memorialId}/${file.name}`,
                type: file.name.split('.').pop()
            }));

        const dbFiles = (memorialData?.music || []).filter(
            file => !storageFiles.some(storageFile => storageFile.name === file.name)
        );

        const combinedFiles = [...storageFiles, ...dbFiles];

        setUploadedFiles(combinedFiles);

        try {
            const { data, error } = await supabase
                .from('memorials')
                .update({ music: combinedFiles })
                .eq('owner_id', userId)
                .eq('memorial_Id', memorialId);

            if (error) {
                throw error;
            }

            console.log('Music URLs updated successfully');
        } catch (error) {
            console.error('Error updating music URLs:', error.message);
        }
    };

    const handleSongSelect = async (fileName) => {
        const musicObject = {
            name: fileName,
            url: `${process.env.PUBLIC_URL}/Audio/${fileName}`,
            type: 'mp3'
        };

        setUploadedFiles(prevFiles => {
            const updatedFiles = [...prevFiles, musicObject];

            updateDatabase(updatedFiles);
            return updatedFiles;
        });
    };

    const handlePlayPause = (fileUrl) => {
        if (audioRef.current) {
            if (playingFile === fileUrl) {
                audioRef.current.pause();
                setPlayingFile(null);
            } else {
                audioRef.current.src = fileUrl;
                audioRef.current.play();
                setPlayingFile(fileUrl);
            }
        }
    };

    const filteredLibraryFiles = audioFiles.filter(fileName =>
        fileName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const isSongUploaded = (fileName) => {
        return uploadedFiles.some(uploadedFile => uploadedFile.name === fileName);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
            <div style={{ width: '90%', maxWidth: '1200px', height: '90%', backgroundColor: '#ffffff', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Music Library
                </Typography>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <Tabs
                        value={tabIndex}
                        onChange={(event, newValue) => setTabIndex(newValue)}
                        aria-label="music tabs"
                        centered
                    >
                        <Tab label="Uploaded Music" />
                        <Tab label="Library Music" />
                    </Tabs>
                    {/* Uploaded Music Tab */}
                    <TabPanel value={tabIndex} index={0}>
                        <div {...getRootProps()} style={{ padding: '20px', border: '2px dashed #ccc', borderRadius: '10px', textAlign: 'center', marginBottom: '20px' }}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <Typography>Drop your files here...</Typography>
                            ) : (
                                <Typography>Drag & drop audio files here, or click to select files</Typography>
                            )}
                        </div>
                        <Grid container spacing={5}>
                            {uploadedFiles.map((file, index) => (
                                <Grid item key={index} xs={12} sm={6} md={4}>
                                    <Card sx={{ width: '20vw', height: '16vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <img src="/images/music.png" alt="thumbnail" width={40} height={40} />
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    textAlign: 'center',
                                                    width: '100%'
                                                }}
                                            >
                                                {file.name}
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
                                                <IconButton onClick={() => handlePlayPause(file.url)}>
                                                    {playingFile === file.url ? <PauseIcon /> : <PlayArrowIcon />}
                                                </IconButton>
                                                <IconButton onClick={() => handleFileDelete(file.name)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </TabPanel>
                    {/* Library Music Tab */}
                    <TabPanel value={tabIndex} index={1}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <SearchIcon />
                            <TextField
                                label="Search music library"
                                variant="outlined"
                                size="small"
                                sx={{ ml: 2 }}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                        <List sx={{ height: 400, overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}>
                            {filteredLibraryFiles.map((fileName, index) => (
                                <ListItem key={index} sx={{ opacity: isSongUploaded(fileName) ? 0.5 : 1 }}>
                                    <ListItemIcon>
                                        <img src="/images/music.png" alt="thumbnail" width={40} height={40} />
                                    </ListItemIcon>
                                    <ListItemText primary={fileName} />
                                    <Box sx={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
                                        <IconButton onClick={() => handlePlayPause(`${process.env.PUBLIC_URL}/Audio/${fileName}`)}>
                                            {playingFile === `${process.env.PUBLIC_URL}/Audio/${fileName}` ? <PauseIcon /> : <PlayArrowIcon />}
                                        </IconButton>
                                       
                                            <IconButton
                                            onClick={() => handleSongSelect(fileName)}
                                            disabled={isSongUploaded(fileName)} // Disable button if the song is already uploaded
                                        >
                                            <PublishIcon />
                                        </IconButton>
                                       
                                    </Box>
                                </ListItem>
                            ))}
                        </List>

                    </TabPanel>
                </Box>
                {loading && <CircularProgress />}
                <audio ref={audioRef} />
            </div>
        </div>
    );
}

export default Music;

// TabPanel component for managing tab content display
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
