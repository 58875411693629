import React, { useState } from 'react';
import { Button, Box } from '@mui/material';

const Donate_button = ({ handler }) => {
  const [hovered, setHovered] = useState(false); // State to control hover

  const handleClick = () => {
    handler(true);
  };

  return (

      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        onMouseEnter={() => setHovered(true)} // Expand on hover
        onMouseLeave={() => setHovered(false)} // Shrink when not hovering
        style={{
          backgroundColor: 'white', // White button
          color: 'black', // Black text
          width: hovered ? '50%' : '50px', // Square when not hovered, expands on hover
          height: '50px', // Fixed height to keep it square
          transition: 'width 0.3s ease', // Smooth width transition
          overflow: 'hidden', // Hide text when button is square
          display: 'flex', // Align icon and text
          alignItems: 'center', // Center content vertically
          justifyContent: hovered ? 'space-between' : 'center', // Center icon when square, space-between when expanded
          padding: hovered ? '0 16px' : '0', // Add padding when expanded
        }}
      >
        {/* Icon */}
        <img src="/images/donate.png" alt="Donate" width="24px" height="24px" />

        {/* Show text only when hovered */}
        {hovered && (
          <Box ml={1}>Leave an offering</Box>
        )}
      </Button>
   
  );
};

export default Donate_button;
