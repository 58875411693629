import React from 'react';
import { Box, Button, Typography, Modal, IconButton } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function ShareModal({ open, handleClose, shareUrl }) {
    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            alert('Link copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const handleShare = (platform) => {
        const encodedUrl = encodeURIComponent(shareUrl);
        const text = encodeURIComponent('Check out this memorial:');
        let shareLink = '';

        switch (platform) {
            case 'facebook':
                const appId = '981836593420700';
                shareLink = `https://www.facebook.com/dialog/share?app_id=${appId}&href=${encodedUrl}&display=popup`;
                break;
            case 'twitter':
                shareLink = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${text}`;
                break;
            case 'email':
                shareLink = `mailto:?subject=${text}&body=${encodedUrl}`;
                break;
            case 'whatsapp':
                shareLink = `https://api.whatsapp.com/send?text=${text} ${encodedUrl}`;
                break;
            default:
                console.error('Unsupported platform:', platform);
                return;
        }

        window.open(shareLink, '_blank');
    };


    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                width: '300px',
                padding: '20px',
                bgcolor: 'background.paper',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography variant="h6" gutterBottom>Share this Memorial</Typography>
                <Box sx={{ width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <IconButton onClick={handleCopyLink} sx={{ width: '80%', justifyContent: 'flex-start' }}>
                        <CopyIcon />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Copy Link</Typography>
                    </IconButton>
                    <IconButton onClick={() => handleShare('facebook')} sx={{ width: '80%', justifyContent: 'flex-start' }}>
                        <FacebookIcon />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Facebook</Typography>
                    </IconButton>
                    <IconButton onClick={() => handleShare('twitter')} sx={{ width: '80%', justifyContent: 'flex-start' }}>
                        <TwitterIcon />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Twitter</Typography>
                    </IconButton>
                    <IconButton onClick={() => handleShare('email')} sx={{ width: '80%', justifyContent: 'flex-start' }}>
                        <EmailIcon />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Email</Typography>
                    </IconButton>
                    <IconButton onClick={() => handleShare('whatsapp')} sx={{ width: '80%', justifyContent: 'flex-start' }}>
                        <WhatsAppIcon />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>WhatsApp</Typography>
                    </IconButton>
                </Box>
                <Button variant="contained" onClick={handleClose} sx={{ marginTop: '10px' }}>Close</Button>
            </Box>
        </Modal>
    );
}

export default ShareModal;
