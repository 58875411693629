import React from 'react';
import { useNavigate } from 'react-router-dom';

function Auth_redirect() {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate('/authentication');
    }, [navigate]);

    return null;
}

export default Auth_redirect;
