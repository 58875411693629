import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';

const Edit_steps = ({ activeStep, className }) => {
    const validatedActiveStep = Math.max(1, Math.min(activeStep, 3)) - 1;

    return (
        <Stepper
            activeStep={validatedActiveStep}
            alternativeLabel
            className={className}
        >
            <Step>
                <StepLabel>Update Memorial Details</StepLabel>
            </Step>
            <Step>
                <StepLabel>Update Obituary</StepLabel>
            </Step>
            <Step>
                <StepLabel>Update Design </StepLabel>
            </Step>
        </Stepper>
    );
};

Edit_steps.propTypes = {
    activeStep: PropTypes.number.isRequired,
    className: PropTypes.string, // Added prop for custom className
};

export default Edit_steps;
