import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createClient } from '@supabase/supabase-js';
import { jwtDecode } from 'jwt-decode';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Navbar2 from './Navbar2';
import FacebookLogin from 'react-facebook-login'; // Import Facebook login package
require('./facebook.css');

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const theme = createTheme();

function Login({ onRegister, setUserId }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Load Google's OAuth2 script
    useEffect(() => {
        const googleScript = document.createElement('script');
        googleScript.src = 'https://accounts.google.com/gsi/client';
        googleScript.async = true;
        googleScript.onload = () => {
            window.google.accounts.id.initialize({
                client_id: '44443408474-so40qd1pkdhdfsl790ncq9ivvrv55oqv.apps.googleusercontent.com',
                callback: handleGoogleSignIn,
            });
            window.google.accounts.id.renderButton(
                document.getElementById('googleSignInButton'),
                { theme: 'outline', size: 'large' }
            );
        };
        document.body.appendChild(googleScript);
    }, []);

    const handleGoogleSignIn = async (response) => {
        try {
            const decodedToken = jwtDecode(response.credential);
            const googleEmail = decodedToken.email;

            // Check if the user exists in Supabase
            const { data, error } = await supabase
                .from('Users')
                .select('id')
                .eq('email', googleEmail)
                .single();

            if (error || !data) {
                setModalMessage('Seems like you don\'t have a Forever Memorials account. Would you like to sign up?');
                setModalOpen(true);
                return;
            }

            // If user exists, log them in
            setUserId(data.id);
            setModalMessage('Login successful!');
            setModalOpen(true);
        } catch (error) {
            console.error('Google login error:', error.message);
            setModalMessage('Login error, please try again.');
            setModalOpen(true);
        }
    };


    const handleFacebookSignIn = async (response) => {
        try {
            const { name, email, picture, id } = response;

            // Check if the user exists in Supabase
            const { data, error } = await supabase
                .from('Users')
                .select('id')
                .eq('email', email)
                .single();

            if (error || !data) {
                setModalMessage('Seems like you don\'t have a Forever Memorials account. Would you like to sign up?');
                setModalOpen(true);
                return;
            }

            // If user exists, log them in
            setUserId(data.id);
            setModalMessage('Login successful!');
            setModalOpen(true);
        } catch (error) {
            console.error('Faebook login error:', error.message);
            setModalMessage('Login error, please try again.');
            setModalOpen(true);
        }
    };


    const handleCloseModal = () => {
        setModalOpen(false);
        setModalMessage('');
    };

    const handleSignUp = () => {
        onRegister(); // Call the onRegister prop to handle sign up
        handleCloseModal(); // Close the modal
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const invalidFields = [];
        if (!email || !isValidEmail(email)) {
            invalidFields.push('email');
        }
        if (!password) {
            invalidFields.push('password');
        }

        if (invalidFields.length > 0) {
            setInvalidFields(invalidFields);
            setModalMessage('Please fill out all required fields correctly.');
            setModalOpen(true);
        } else {
            try {
                const { data, error } = await supabase
                    .from('Users')
                    .select('id')
                    .eq('email', email)
                    .eq('password', password)
                    .single();

                if (error) {
                    throw error;
                }

                if (data) {
                    setUserId(data.id); // Pass the user ID to the parent component
                    setModalMessage('Login Successful!');
                    setModalOpen(true);
                } else {
                    setModalMessage('Invalid email or password.');
                    setModalOpen(true);
                }
            } catch (error) {
                console.error('Login error:', error.message);
                setModalMessage('Login error, please try again.');
                setModalOpen(true);
            }
        }
    };

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div>
            <Navbar2 />
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    component="main"
                    sx={{
                        height: '100vh',
                        backgroundImage: 'url(https://images.unsplash.com/photo-1610027562252-2ed79aa944d7?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mb: 3,
                            mt: -6,
                        }}
                    >
                        <img src="/images/lock.png" alt="Lock Icon" style={{ width: 40, height: 40 }} />
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            sx={{
                                fontFamily: "'Kaushan Script', cursive !important",
                                color: 'white',
                                mt: 1,
                            }}
                        >
                            Sign In
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            backdropFilter: 'blur(10px)',
                            padding: 4,
                            borderRadius: 3,
                            boxShadow: 3,
                            maxWidth: 450,
                            width: '90%',
                            px: 2,
                        }}
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={invalidFields.includes('email')}
                                sx={{
                                    borderRadius: 20,
                                    '.MuiInputBase-root': {
                                        borderRadius: '20px',
                                    },
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={invalidFields.includes('password')}
                                sx={{
                                    borderRadius: 20,
                                    '.MuiInputBase-root': {
                                        borderRadius: '20px',
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#003365',
                                    color: 'white',
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#002750',
                                    },
                                }}
                            >
                                Sign In
                            </Button>

                            <Typography>Or</Typography>

                            {/* Google Sign In Button */}
                            <Button
                                id="googleSignInButton"
                            >
                                Sign in with Google
                            </Button>

                            {/* Facebook Sign In Button */}
                            <FacebookLogin
                                appId="981836593420700"
                                fields="name,email,picture"
                                callback={handleFacebookSignIn}
                                cssClass="facebook-button"
                                icon="fa-facebook"
                                textButton="&nbsp;&nbsp;Sign In with Facebook"
                            />

                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="#" variant="body2" onClick={onRegister}>
                                        Don't have an account? Sign up
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Modal
                        open={modalOpen}
                        onClose={handleCloseModal}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 3,
                                borderRadius: 2,
                                minWidth: '300px',
                                maxWidth: {
                                    xs: '90%', // Full width on mobile
                                    sm: '400px', // Reduced width on small screens
                                    md: '350px', // Further reduced width on medium screens
                                    lg: '600px', // Reduced width on large screens
                                    xl: '400px', // Reduced width on extra large screens
                                },
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h6">{modalMessage}</Typography>
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSignUp}
                                    sx={{
                                        borderRadius: '20px',
                                    }}
                                >
                                    Sign Up
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleCloseModal}
                                    sx={{
                                        borderRadius: '20px',
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Paper>
                    </Modal>

                </Grid>
            </ThemeProvider>
        </div >
    );
}

export default Login;
