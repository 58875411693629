import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function VideoGallery() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [videos, setVideos] = useState([]);
    const [activeVideo, setActiveVideo] = useState(null);
    const [hoveredVideo, setHoveredVideo] = useState(null);
    const videoRefs = useRef({});
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');

    useEffect(() => {
        const fetchVideos = async () => {
            const { data: memorialData, error: memorialError } = await supabase
                .from('memorials')
                .select('media, deceased_name, deceased_nickname, deceased_surname')
                .eq('owner_id', userId)
                .eq('memorial_Id', memorialId);

            if (memorialError) {
                console.error('Error fetching videos:', memorialError.message);
                return;
            }

            if (memorialData && memorialData.length > 0) {
                const videoFiles = memorialData[0]?.media?.filter(file => ['mp4', 'webm', 'ogg'].includes(file.type)) || [];
                setVideos(videoFiles.map(video => video.url));
                setActiveVideo(videoFiles[0]?.url);
                setDeceasedName(memorialData[0]?.deceased_name);
                setDeceasedNickname(memorialData[0]?.deceased_nickname);
                setDeceasedSurname(memorialData[0]?.deceased_surname);
            }
        };

        fetchVideos();
    }, [userId, memorialId]);

    const handleMouseEnter = (videoUrl) => {
        setHoveredVideo(videoUrl);
        videoRefs.current[videoUrl].play();
    };

    const handleMouseLeave = (videoUrl) => {
        setHoveredVideo(null);
        videoRefs.current[videoUrl].pause();
    };

    return (
        <div style={{ backgroundImage: `url('/images/background1.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <div style={{ width: '85%', height: '85%', backgroundColor: 'rgba(255, 255, 255, 0.95)', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>

                <h1>Videos of {deceasedName} "{deceasedNickname}" {deceasedSurname}</h1>
                <br></br>
                {activeVideo && (
                    <video key={activeVideo} controls style={{ width: '80%', maxHeight: '60vh' }}>
                        <source src={activeVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '80%', padding: '10px 0' }}>
                    {videos.map((videoUrl, index) => (
                        <Card key={index} onClick={() => setActiveVideo(videoUrl)} onMouseEnter={() => handleMouseEnter(videoUrl)} onMouseLeave={() => handleMouseLeave(videoUrl)} style={{ width: '200px', height: '100px', objectFit: 'cover', margin: '5px', position: 'relative' }}>
                            <video ref={ref => videoRefs.current[videoUrl] = ref} src={videoUrl} style={{ width: '100%', height: '100%' }} preload="metadata" muted />
                            {hoveredVideo !== videoUrl && <PlayArrowIcon style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '3rem' }} />}
                        </Card>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default VideoGallery;
