import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill stylesheet

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


function View_about() {
    const [content, setContent] = useState('');
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');

    useEffect(() => {
        const fetchContent = async () => {
            const userId = Cookies.get('userId');
            const memorialId = Cookies.get('memorialId');

            if (userId && memorialId) {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('about, deceased_name, deceased_surname, deceased_nickname') // Fetching the required fields
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId)
                    .single();

                if (error) {
                    console.error('Error fetching content:', error);
                } else if (data) {
                    setContent(data.about);
                    setDeceasedName(data.deceased_name);
                    setDeceasedSurname(data.deceased_surname);
                    setDeceasedNickname(data.deceased_nickname);
                }
            }
        };

        fetchContent();
    }, []);

    return (
        <div style={{ margin: 0 }}> {/* Set margin: 0 to remove any inherited margin */}

            <h1>About {deceasedName} "{deceasedNickname}" {deceasedSurname}</h1>
            <br></br>
            {/* Here, ReactQuill is used just to render content, not to edit it */}
            <ReactQuill
                value={content}
                readOnly={true}
                theme={"bubble"} // Use the "bubble" theme for a simpler appearance
            />
        </div>


    );
}

export default View_about;
