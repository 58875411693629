import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import Navbar_public from './Navbar_public';

const Terms_of_service = () => {
  return (
    <div>
      <Navbar_public />
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Terms of Service
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center">
            Effective Date: [02/10/2024]
          </Typography><br/>
          <Typography variant="body1" paragraph align="left">
            Welcome to forever.org.za. By using our website, you agree to comply with and be bound by the following Terms of Service. Please read these terms carefully before using our website.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph align="left">
            By accessing or using forever.org.za (referred to as "the Website"), you agree to be legally bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, please do not use the Website.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            2. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We reserve the right to update or modify these Terms of Service at any time without prior notice. The latest version will be posted on this page with an updated effective date. Continued use of the Website after any changes constitutes your acceptance of the revised terms.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            3. Use of the Website
          </Typography>
          <Typography variant="body1" paragraph align="left">
            You agree to use the Website only for lawful purposes and in accordance with these Terms of Service. You are prohibited from:
            <ul>
              <li>Engaging in any conduct that may harm the Website or disrupt its operation.</li>
              <li>Attempting to access unauthorized areas of the Website or use the Website in any illegal or unethical manner.</li>
              <li>Using the Website to upload, post, or distribute any material that is unlawful, harmful, defamatory, or otherwise objectionable.</li>
              <li>Violating any local, national, or international laws or regulations in your use of the Website.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            4. User Accounts
          </Typography>
          <Typography variant="body1" paragraph align="left">
            In order to access certain features of the Website, you may be required to create a user account. You agree to:
            <ul>
              <li>Provide accurate and complete information when creating your account.</li>
              <li>Maintain the security of your account by keeping your password confidential.</li>
              <li>Notify us immediately of any unauthorized use of your account or any other security breach.</li>
              <li>Be responsible for all activities that occur under your account.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            5. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph align="left">
            All content on this Website, including but not limited to text, graphics, images, logos, and software, is the property of forever.org.za or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or create derivative works of any material without prior written permission from forever.org.za.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            6. User-Generated Content
          </Typography>
          <Typography variant="body1" paragraph align="left">
            By submitting content (such as memorials, comments, or media) to the Website, you:
            <ul>
              <li>Grant forever.org.za a non-exclusive, royalty-free, perpetual, and worldwide license to use, display, distribute, and modify your content.</li>
              <li>Warrant that you own the rights to the content or have obtained all necessary permissions to submit the content.</li>
              <li>Acknowledge that forever.org.za reserves the right to remove or modify any user-generated content at its sole discretion.</li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            7. Third-Party Links
          </Typography>
          <Typography variant="body1" paragraph align="left">
            The Website may contain links to third-party websites. These links are provided for your convenience, and forever.org.za is not responsible for the content or practices of any linked sites. Access to any third-party websites is at your own risk, and we encourage you to review the terms and privacy policies of those websites.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            8. Termination of Use
          </Typography>
          <Typography variant="body1" paragraph align="left">
            We reserve the right, in our sole discretion, to terminate or suspend your access to the Website at any time, with or without notice, for any reason, including violation of these Terms of Service.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            9. Disclaimers
          </Typography>
          <Typography variant="body1" paragraph align="left">
            <strong>No Warranties:</strong> The Website is provided "as is" without warranties of any kind, either express or implied. forever.org.za does not guarantee the accuracy, completeness, or reliability of the information on the Website.
            <br />
            <strong>Limitation of Liability:</strong> forever.org.za will not be liable for any damages arising from your use of or inability to use the Website, including direct, indirect, incidental, or consequential damages, even if we have been advised of the possibility of such damages.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            10. Indemnification
          </Typography>
          <Typography variant="body1" paragraph align="left">
            You agree to indemnify and hold harmless forever.org.za, its officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of your use of the Website or your violation of these Terms of Service.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            11. Governing Law
          </Typography>
          <Typography variant="body1" paragraph align="left">
            These Terms of Service shall be governed by and construed in accordance with the laws of South Africa, without regard to its conflict of law principles. Any disputes arising from or related to these Terms or the use of the Website will be subject to the exclusive jurisdiction of the courts of South Africa.
          </Typography>

          <Typography variant="h6" gutterBottom align="left">
            12. Contact Information
          </Typography>
          <Typography variant="body1" paragraph align="left">
            If you have any questions or concerns about these Terms of Service, please contact us at:
            <ul>
              <li>Forever Memorials Email: info@forever.org.za</li>
              <li>Website: <a href="https://www.forever.org.za">https://www.forever.org.za</a></li>
            </ul>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Terms_of_service;
