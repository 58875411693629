import React from 'react'
import { Image, Transformer } from 'react-konva';

const Static_image = ({ src, id, x, y, width, height, scaleX, scaleY, isSelected, onSelect, onDelete, onDuplicate, onBringForward, onSendBackward }) => {
    const imageRef = React.useRef();
    const trRef = React.useRef();
    const [image] = React.useState(new window.Image());

    React.useEffect(() => {
        image.src = src;
        image.onload = () => {
            if (imageRef.current) {
                imageRef.current.getLayer().draw();
            }
        };
    }, [src, image]);

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([imageRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const handleRightClick = (event) => {
        event.evt.preventDefault();
        // Display context menu
        const menu = document.getElementById('context-menu');
        menu.style.top = `${event.evt.clientY}px`;
        menu.style.left = `${event.evt.clientX}px`;
        menu.style.display = 'block';

        // Set handlers for menu items
        document.getElementById('duplicate').onclick = () => {
            onDuplicate();
            menu.style.display = 'none';  // Hide context menu
        };
        document.getElementById('delete').onclick = () => {
            onDelete();
            menu.style.display = 'none';  // Hide context menu
        };
        document.getElementById('bringForward').onclick = () => {
            console.log('Bring Forward clicked');
            onBringForward();
            console.log('onBringForward called');
            menu.style.display = 'none';
            console.log('Context menu hidden');
        };

        document.getElementById('sendBackward').onclick = () => {
            console.log('Send Backward clicked');
            onSendBackward();
            console.log('onSendBackward called');
            menu.style.display = 'none';
            console.log('Context menu hidden');
        };
    };

    return (
        <React.Fragment>
            <Image
                id={id}
                src={src}
                image={image}
                ref={imageRef}
                onClick={onSelect}
                onTap={onSelect}
                onContextMenu={handleRightClick}
                draggable
                width={width}
                height={height}
                scaleX={scaleX}
                scaleY={scaleY}
                x={x}
                y={y}
            />
            {isSelected && <Transformer ref={trRef} listening={isSelected} />}
            {!isSelected && <Transformer ref={trRef} listening={isSelected} />}
        </React.Fragment>
    )
}

export default Static_image
