import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { Card, CardHeader, Typography, Paper, IconButton, CardActions, Modal, Box } from '@mui/material';
import AudioIcon from '@mui/icons-material/Audiotrack';
import VoiceClips from './Voice_clips';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Voice_Player_public({ memorialId }) {
    const userId = Cookies.get('userId');
    const [voiceClips, setVoiceClips] = useState([]);
    const [currentClipIndex, setCurrentClipIndex] = useState(null);
    const audioRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [refreshPlayer, setRefreshPlayer] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        const fetchVoiceClips = async () => {
            const { data: memorialData, error: memorialError } = await supabase
                .from('memorials')
                .select('voice_clips')
                .eq('memorial_Id', memorialId);

            if (memorialError) {
                console.error('Error fetching voice clips:', memorialError.message);
                return;
            }

            if (memorialData && memorialData.length > 0) {
                const clips = memorialData[0]?.voice_clips || [];
                setVoiceClips(clips);
                // Preload the first clip by default
                if (clips.length > 0) {
                    setCurrentClipIndex(0);
                }
            }
        };

        fetchVoiceClips();
    }, [userId, memorialId, refreshPlayer]);

    const handleClipClick = (index) => {
        setCurrentClipIndex(index);
        if (audioRef.current) {
            audioRef.current.load();
            audioRef.current.play();
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setRefreshPlayer(!refreshPlayer); // Toggle refresh to force re-render
    };

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    // Only render the component if there are voice clips
    if (voiceClips.length === 0) {
        return null;
    }

    return (
        <>
            <Card elevation={10} style={{ width: '100%', padding: '10px', marginTop: '10px', height: 'auto', borderRadius: '10px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <CardHeader
                    title="Voice Clips"
                    sx={{
                        padding: '2%', // Reduce padding
                        '& .MuiTypography-root': { // Target the Typography inside the CardHeader
                            fontSize: '1.3rem', // Reduce the font size
                        },
                        position: 'sticky',
                        top: '-10px',
                        bgcolor: '#fff',
                        zIndex: 1000,
                        borderRadius: '10px'
                    }}
                />
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={scrollLeft}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <div ref={scrollRef} style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', scrollbarWidth: 'none', msOverflowStyle: 'none', flexGrow: 1 }}>
                        <div style={{ display: 'flex', width: 'max-content' }}>
                            {voiceClips.map((clip, index) => (
                                <Paper
                                    key={index}
                                    elevation={currentClipIndex === index ? 4 : 0} // Highlight the selected clip
                                    style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        textAlign: 'center',
                                        border: currentClipIndex === index ? '2px solid #3f51b5' : 'none',
                                        margin: '0 5px',
                                        minWidth: '100px',
                                    }}
                                    onClick={() => handleClipClick(index)}
                                >
                                    <AudioIcon style={{ fontSize: 20, color: '#3f51b5' }} />
                                    <Typography variant="body2" style={{ marginTop: '8px', textAlign: 'center', fontSize: '10px' }}>
                                        {clip.name.length > 20 ? `${clip.name.substring(0, 20)}...` : clip.name}
                                    </Typography>
                                </Paper>
                            ))}
                        </div>
                    </div>
                    <IconButton onClick={scrollRight}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </div>
                <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                    <audio controls ref={audioRef} autoPlay={false} style={{ width: '100%' }}>
                        <source src={voiceClips[currentClipIndex]?.url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </CardActions>
            </Card>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="edit-voice-clips-modal"
                aria-describedby="modal containing voice clips editor"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ outline: 'none' }}>
                    <VoiceClips />
                </Box>
            </Modal>
        </>
    );
}

export default Voice_Player_public;
