import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import { Card, CardContent, Typography, Grid, IconButton, Button, Dialog, DialogTitle } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import MovieIcon from '@mui/icons-material/Movie';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Edit_media() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [uploadedFiles, setUploadedFiles] = useState([]); // State to store uploaded files
    const [open, setOpen] = useState(false); // State to control the dialog
    const [open2, setOpen2] = useState(false); // State to control the dialog
    const [open3, setOpen3] = useState(false); // State to control the dialog
    const navigate = useNavigate();

    useEffect(() => {
        updateFilesInDirectory(); // Fetch files when component mounts
    }, []); // Empty dependency array means it runs only once when mounted

    const onDrop = React.useCallback((acceptedFiles) => {
        acceptedFiles.forEach(file => {
            handleFileUpload(file);
        });
    }, [userId, memorialId]); // Dependencies are necessary here for React hooks

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*,audio/*,video/*',
        maxSize: 50242880, // 5MB
    });

    const handleFileUpload = async (file) => {
        if (!userId || !memorialId) {
            console.error('Missing userId or memorialId cookies');
            return;
        }

        const bucketName = 'memorial_assets';

        // Upload file directly to the bucket
        const { data, error } = await supabase.storage
            .from(bucketName)
            .upload(`public/${userId}/${memorialId}/${file.name}`, file, {
                upsert: true // If file exists, it will be replaced
            });

        if (error) {
            console.error('Error uploading file:', error.message);
            return;
        }

        console.log('File uploaded successfully:', data.Key);

        // Update the list of files in the directory and the database
        await updateFilesInDirectory();
    };

    const handleFileDelete = async (fileName) => {
        const bucketName = 'memorial_assets';

        // Delete file from the bucket
        const { data, error } = await supabase.storage
            .from(bucketName)
            .remove([`public/${userId}/${memorialId}/${fileName}`]);

        if (error) {
            console.error('Error deleting file:', error.message);
            return;
        }

        console.log('File deleted successfully:', data);

        // Update the list of files in the directory and the database
        await updateFilesInDirectory();
    };

    const updateFilesInDirectory = async () => {
        const bucketName = 'memorial_assets';

        // Get list of files in the memorialId folder
        const { data: fileList, error: listError } = await supabase.storage
            .from(bucketName)
            .list(`public/${userId}/${memorialId}`);

        if (listError) {
            console.error('Error listing files:', listError.message);
            return;
        }

        // Construct URLs for all files and save them in a JavaScript object
        const mediaObject = fileList.map(file => ({
            name: file.name,
            url: `${supabaseUrl}/storage/v1/object/public/${bucketName}/public/${userId}/${memorialId}/${file.name}`,
            type: file.name.split('.').pop() // Get the file extension
        }));

        // Update state with new uploaded files
        setUploadedFiles(mediaObject);

        // Save URLs to database
        try {
            const { data, error } = await supabase
                .from('memorials')
                .update({ media: mediaObject })
                .eq('owner_id', userId)
                .eq('memorial_Id', memorialId);

            if (error) {
                throw error;
            }

            console.log('Media URLs updated successfully');
        } catch (error) {
            console.error('Error updating media URLs:', error.message);
        }
    };

    const handleSaveAndContinue = () => {
        setOpen(true);
    };

    const handleSaveAndExit = () => {
        setOpen2(true);
    };
    const handleSave = () => {
        setOpen3(true);
    };

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background1.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>S
                <div style={{ width: '85%', height: '85%', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                    <h2>Upload Media</h2>
                    <br></br>
                    <div {...getRootProps()} style={{ border: '2px dashed black', padding: '20px' }}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>
                    <br></br>
                    <h3>Uploaded Files:</h3>
                    <br></br>
                    <Grid container spacing={2} justifyContent="center">
                        {uploadedFiles.map((file, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card style={{
                                    width: '100%',
                                    height: '100%',
                                    margin: '0 auto',
                                    backgroundColor: ['jpg', 'png', 'gif', 'webp', 'jpeg', 'PNG'].includes(file.type) ? '#66a1ed' :
                                        ['mp3', 'wav'].includes(file.type) ? '#edc966' :
                                            ['mp4', 'avi'].includes(file.type) ? '#ed6671' : '#fff'
                                }}>
                                    <CardContent>
                                        <IconButton onClick={() => handleFileDelete(file.name)} style={{ float: 'right' }}>
                                            <DeleteIcon />
                                        </IconButton>
                                        {['jpg', 'png', 'gif'].includes(file.type) && <ImageIcon />}
                                        {['mp3', 'wav'].includes(file.type) && <AudiotrackIcon />}
                                        {['mp4', 'avi'].includes(file.type) && <MovieIcon />}
                                        {!['jpg', 'png', 'gif', 'mp3', 'wav', 'mp4', 'avi'].includes(file.type) && <DescriptionIcon />}
                                        <Typography variant="h6">{file.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="primary" onClick={handleSaveAndExit}>Save & Exit</Button>
                        <div style={{ marginLeft: '8px' }}></div> {/* Add space between buttons */}
                        <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                        <div style={{ marginLeft: '8px' }}></div> {/* Add space between buttons */}
                        <Button variant="contained" color="primary" onClick={handleSaveAndContinue}>Save & Continue</Button>
                    </div>

                    <Dialog open={open} onClose={() => {
                        setOpen(false);
                        setTimeout(() => {
                            navigate('/Edit_memorial'); // Redirect to /Design_memorial after 2 seconds
                        }, 2000);
                    }}>
                        <DialogTitle>Media Uploaded Successfully</DialogTitle>
                    </Dialog>

                    <Dialog open={open2} onClose={() => {
                        setOpen2(false);
                        setTimeout(() => {
                            navigate('/Memorial_list'); // Redirect to /Design_memorial after 2 seconds
                        }, 2000);
                    }}>
                        <DialogTitle>Media Uploaded Successfully</DialogTitle>
                    </Dialog>

                    <Dialog open={open3} onClose={() => {
                        setOpen3(false);
                        setTimeout(() => {
                        }, 2000);
                    }}>
                        <DialogTitle>Media Uploaded Successfully</DialogTitle>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Edit_media;
