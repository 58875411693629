import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { Box, Typography, CardContent } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const StreamContainer = styled(Box)({
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '10px',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    position: 'relative',
    width: '100%',
    height: '20vh',  // Adjust height as needed
    alignItems: 'center',
});

const ScrollWrapper = styled(Box)({
    display: 'flex',
    animation: `${scroll} 20s linear infinite`,
});

const MessageContent = styled(CardContent)({
    padding: '2px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
});

const MessageText = styled(Typography)({
    color: 'white',  // Text color is white
    marginLeft: '10px',
    fontSize: '1.2rem',  // Increased font size
    fontWeight: 'bold',  // Set text to bold
});

const Rose_stream = () => {
    const [roses, setRoses] = useState([]);

    useEffect(() => {
        const fetchRoses = async () => {
            const memorialId = Cookies.get('memorialId');

            const { data, error } = await supabase
                .from('memorials')
                .select('roses')
                .eq('memorial_Id', memorialId)
                .single();

            if (error) {
                console.error(error);
                return;
            }

            if (data?.roses && data.roses.length > 0) {
                setRoses(data.roses);
            }
        };

        fetchRoses();
    }, []);

    if (roses.length === 0) {
        return null; // Do not render the component if there are no roses
    }

    return (
        <StreamContainer>
            <ScrollWrapper>
                {roses.map((rose, index) => (
                    <Box key={index} sx={{ minWidth: '180px', margin: '10px', height: '5.5vh', backgroundColor: 'transparent' }}>
                        <MessageContent>
                            <MessageText variant="p">
                            🌹  {rose.name} {rose.surname} 
                            </MessageText>
                        </MessageContent>
                    </Box>
                ))}
            </ScrollWrapper>
        </StreamContainer>
    );
};

export default Rose_stream;
