import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Image, Transformer, Text } from 'react-konva';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { InfinitySpin } from 'react-loader-spinner';
import { Draggable } from 'react-draggable'; // If you wish to use a library for better control
import 'gifler';
import {
    TextField,
    Grid,
    Button,
    ImageList,
    ImageListItem,
    Box,
    CircularProgress,
    Typography,
    Drawer,
    IconButton,
    BackdropFilter,
    styled,
    Modal
} from '@mui/material';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import Messages from './Messages';
import View_about from './View_about';
import Audio_Player from './Audio_player';
import About_card from './About_card';
import Media_Player from './Media_player';
import Voice_clips from './Voice_clips';
import Voice_player from './Voice_player';
import Edit_memorial_modal from './Edit_memorial_modal'; // Import the Edit_memorial component
import CreateIcon from '@mui/icons-material/Create';
import Navbar from './Navbar';
import Edit_memorial_info_modal from './Edit_memorial_info_modal';



const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


const assetCategories = {
    "Tombstones": ['/images/tombstone1.png', '/images/tombstone2.png', '/images/tombstone3.png', '/images/tombstone4.png'],
    "Animations": ['/images/dove.gif', '/images/orangebird.gif', '/images/dove3.gif'],
    "Trees": ['/images/tree1.png', '/images/tree2.png', '/images/tree3.png', '/images/tree4.png'],
    "Statues": ['/images/statue1.png', '/images/statue2.png', '/images/statue3.png', '/images/candle.png'],
    "Flowers": ['/images/flower1.png', '/images/flower2.png', '/images/flower3.png'],
    "Football Clubs": ['/images/football1.png', '/images/football2.png', '/images/football3.png'],
    "Car brands": ['/images/car1.png', '/images/car2.png', '/images/car3.png'],
    "Country Flags": ['/images/flag1.png', '/images/flag2.jpg', '/images/flag3.png'],
    "Landscapes": ['/images/landscape1.png', '/images/landscape2.png', '/images/landscape3.png', '/images/landscape4.png']
};

const BackgroundImage = ({ src, stageWidth, stageHeight }) => {
    const imageRef = React.useRef();
    const [image] = React.useState(new window.Image());

    React.useEffect(() => {
        image.src = src;
        image.onload = () => {
            if (imageRef.current) {
                imageRef.current.getLayer().draw();
            }
        };
    }, [src, image]);

    return (
        <Image
            image={image}
            ref={imageRef}
            width={stageWidth}
            height={stageHeight}
            listening={false} // Ignore pointer events
            fit="cover" // Scale the image to cover the entire area of the Image node
        />
    );
};

const CanvasText = ({ x, y, text, fontFamily, fontSize, scaleX, scaleY, fontStyle, fontWeight, fill, height, width }) => {



    return (

        <Text
            x={x}
            y={y}
            scaleX={scaleX}
            scaleY={scaleY}
            text={text}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontStyle={fontStyle}
            fontWeight={fontWeight}
            fill={fill}
            width={width}
            height={height}

        />

    );
};


const Dove = ({ src, position, size }) => {
    const imageRef = React.useRef();
    const trRef = React.useRef();
    const canvas = React.useMemo(() => {
        const node = document.createElement('canvas');
        return node;
    }, []);
    const anim = React.useRef();

    // Store the src attribute of the GIFs
    if (imageRef.current) {
        imageRef.current.attrs.gifSrc = src;
    }

    React.useEffect(() => {
        window.gifler(src).get((a) => {
            anim.current = a;
            anim.current.animateInCanvas(canvas);
            anim.current.onDrawFrame = (ctx, frame) => {
                ctx.drawImage(frame.buffer, frame.x, frame.y);
                if (imageRef.current) {
                    imageRef.current.getLayer().draw();
                }
            };
        });
        return () => {
            if (anim.current) {
                anim.current.stop();
            }
        };
    }, [src, canvas]);

    return (

        <Image
            image={canvas}
            ref={imageRef}
            width={size.width} // Use asset.width for width
            height={size.height} // Use asset.height for height
            x={position.x} // Use asset.x for x position
            y={position.y} // Use asset.y for y position
        />


    );
};




const StaticImage = ({ src, position, size }) => {
    const imageRef = React.useRef();
    const trRef = React.useRef();
    const [image] = React.useState(new window.Image());

    React.useEffect(() => {
        image.src = src;
        image.onload = () => {
            if (imageRef.current) {
                imageRef.current.getLayer().draw();
            }
        };
    }, [src, image]);

    return (

        <Image
            image={image}
            ref={imageRef}
            width={size?.width}
            height={size?.height}
            x={position?.x}
            y={position?.y}
        />

    );
};



const AssetComponent = ({ assetCategories, selectAsset }) => {
    const allAssets = Object.entries(assetCategories).flatMap(([category, assets]) =>
        assets.map((asset) => ({ category, src: asset }))
    );
    const [filteredAssets, setFilteredAssets] = useState(allAssets);
    const [searchTerm, setSearchTerm] = useState('');
    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const filterByCategory = (category) => {
        if (category === 'All') {
            setFilteredAssets(allAssets);
        } else {
            setFilteredAssets(allAssets.filter(asset => asset.category === category));
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredAssets(allAssets.filter(asset =>
            asset.src.toLowerCase().includes(e.target.value.toLowerCase())
        ));
    };

    const handleAssetClick = (asset) => {
        selectAsset(asset.src, asset.category)();
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <Box sx={{ border: '5px ridge black', overflowX: 'auto', height: '100%', scrollbarWidth: 'none' }}>
            <TextField value={searchTerm} onChange={handleSearchChange} placeholder="Search elements..." variant="outlined" sx={{ borderColor: 'black', borderWidth: 5 }} fullWidth />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                <IconButton onClick={() => scrollContainerRef.current.scrollLeft -= 100} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    <ArrowBackIcon />
                </IconButton>
                <Box
                    ref={scrollContainerRef}
                    sx={{ display: 'flex', overflowX: 'auto', gap: '10px', p: 1, cursor: 'grab', scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseUp}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                >
                    <Button onClick={() => filterByCategory('All')} variant="contained" sx={{ minWidth: '170px', height: '30px' }} style={{ width: 'fit-content' }}>
                        Show All
                    </Button>
                    {Object.keys(assetCategories).map(category => (
                        <Button key={category} onClick={() => filterByCategory(category)} variant="contained" sx={{ minWidth: '170px', height: '30px' }} style={{ width: 'fit-content' }}>
                            {category}
                        </Button>
                    ))}
                </Box>
                <IconButton onClick={() => scrollContainerRef.current.scrollLeft += 100} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
                <ImageList variant="quilted" cols={3} gap={22} >
                    {filteredAssets.map(asset => (
                        <ImageListItem key={asset.src} sx={{
                            border: '2px solid black',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            '& img': {
                                transition: 'transform 0.3s ease-in-out',
                            },
                            '&:hover': {
                                '& img': {
                                    transform: 'scale(1.1)',
                                },
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.11)',
                                }
                            }
                        }}>
                            <img
                                src={asset.src}
                                alt={asset.category}
                                onClick={() => handleAssetClick(asset)}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </Box>
    );
};









class Memorial_viewer_public extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: Cookies.get('userId'),
            memorialId: this.props.memorialId, // Access the specific prop
            memorial: null,
            assets: [],
            loading: true, // Add a new state variable for loading
            assetsLoaded: 0, // Add a new state variable for the number of assets loaded
            selectedAsset: null,
            selectedCategory: null,
            background: '/images/landscape1.png',
            assetCategories: {
                "Tombstones": ['/images/tombstone1.png', '/images/tombstone2.png', '/images/tombstone3.png', '/images/tombstone4.png'],
                "Animations": ['/images/dove.gif', '/images/orangebird.gif', '/images/dove3.gif'],
                "Trees": ['/images/tree1.png', '/images/tree2.png', '/images/tree3.png', '/images/tree4.png'],
                "Statues": ['/images/statue1.png', '/images/statue2.png', '/images/statue3.png', '/images/candle.png'],
                "Flowers": ['/images/flower1.png', '/images/flower2.png', '/images/flower3.png'],
                "Football Clubs": ['/images/football1.png', '/images/football2.png', '/images/football3.png'],
                "Car brands": ['/images/car1.png', '/images/car2.png', '/images/car3.png'],
                "Country Flags": ['/images/flag1.png', '/images/flag2.jpg', '/images/flag3.png'],
                "Landscapes": ['/images/landscape1.png', '/images/landscape2.png', '/images/landscape3.png', '/images/landscape4.png']
            },
            drawerOpen: false, // Add a state for drawerOpen
            contextMenuVisible: false,
            contextMenuPosition: { top: 0, left: 0 },
            contextMenuActions: {
                duplicate: null,
                delete: null
            },
            stageScale: 1,
            stageWidth: window.innerWidth,
            stageHeight: window.innerHeight,
            modalOpen: false, // Add state for modal open/close
            modal2Open: false, // Add state for modal open/close
        };
        this.stageRef = React.createRef();
    }


    componentDidMount() {
        this.fetchMemorial();
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // initial scale
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const container = document.querySelector('.canvas-container');
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        const stageWidth = 1366; // base width for design
        const stageHeight = 768 // base height for design

        // Calculate scale factors
        const scaleX = containerWidth / stageWidth;
        const scaleY = containerHeight / stageHeight;
        const scale = Math.min(scaleX, scaleY);

        // Calculate scaled dimensions
        const scaledWidth = stageWidth * scale;
        const scaledHeight = stageHeight * scale;

        // Calculate maximum allowed translation to keep Stage within bounds
        const maxTranslateX = Math.min(0, containerWidth - scaledWidth);
        const maxTranslateY = Math.min(0, containerHeight - scaledHeight);

        this.setState({
            stageScale: scale,
            stageWidth: scaledWidth,
            stageHeight: scaledHeight,
            maxTranslateX: maxTranslateX,
            maxTranslateY: maxTranslateY,
        }, () => {
            container.style.width = `${scaledWidth}px`;
            container.style.height = `${scaledHeight}px`;
        });
    };




    async fetchMemorial() {
        const { userId, memorialId } = this.state;

        const { data, error } = await supabase
            .from('memorials')
            .select(`
            memorial_canvas,
            deceased_name, 
            deceased_surname, 
            deceased_nickname, 
            deceased_date_of_birth, 
            deceased_date_of_death
        `)

            .eq('memorial_Id', memorialId)
            .single();

        if (error) {
            console.error('Error fetching memorial:', error);
            return;
        }

        this.setState({
            memorial: data.memorial_canvas,
            deceased_name: data.deceased_name,
            deceased_surname: data.deceased_surname,
            deceased_nickname: data.deceased_nickname,
            deceased_date_of_birth: data.deceased_date_of_birth,
            deceased_date_of_death: data.deceased_date_of_death
        });




    }

    handleAssetLoad = () => {
        this.setState(prevState => ({ assetsLoaded: prevState.assetsLoaded + 1 }), () => {
            if (this.state.assetsLoaded === memorial.assets.length + 1) { // +1 for the background image
                this.setState({ loading: false });
            }
        });
    };

    state = {
        assets: [],
        selectedAsset: null,
        selectedCategory: null,
        background: '/images/landscape1.png',
        assetCategories: {
            "Tombstones": ['/images/tombstone1.png', '/images/tombstone2.png', '/images/tombstone3.png', '/images/tombstone4.png'],
            "Animations": ['/images/dove.gif', '/images/orangebird.gif', '/images/dove3.gif'],
            "Trees": ['/images/tree1.png', '/images/tree2.png', '/images/tree3.png', '/images/tree4.png'],
            "Statues": ['/images/statue1.png', '/images/statue2.png', '/images/statue3.png', '/images/candle.png'],
            "Flowers": ['/images/flower1.png', '/images/flower2.png', '/images/flower3.png'],
            "Football Clubs": ['/images/football1.png', '/images/football2.png', '/images/football3.png'],
            "Car brands": ['/images/car1.png', '/images/car2.png', '/images/car3.png'],
            "Country Flags": ['/images/flag1.png', '/images/flag2.jpg', '/images/flag3.png'],
            "Landscapes": ['/images/landscape1.png', '/images/landscape2.png', '/images/landscape3.png', '/images/landscape4.png']
        },
        drawerOpen: false, // Add a state for drawerOpen
        contextMenuVisible: false,
        contextMenuPosition: { top: 0, left: 0 },
        contextMenuActions: {
            duplicate: null,
            delete: null
        }
    };

    toggleDrawer = (open) => {
        this.setState({ drawerOpen: open });
    };

    selectAsset = (src, category) => () => {
        if (category === 'Landscapes') {
            this.setState({ background: src });
        } else {
            this.setState({
                assets: [...this.state.assets, { id: Math.random(), src: src, category: category, selected: false }],
            });
        }
    };

    toggleSelectAsset = (id) => () => {
        this.setState({
            assets: this.state.assets.map((asset) => (asset.id === id ? { ...asset, selected: !asset.selected } : { ...asset, selected: false })),
        });
    };

    deleteAsset = (id) => () => {
        this.setState({
            assets: this.state.assets.filter((asset) => asset.id !== id),
        });
    };

    duplicateAsset = (id) => () => {
        const assetToDuplicate = this.state.assets.find((asset) => asset.id === id);
        const duplicatedAsset = { ...assetToDuplicate, id: Math.random() };

        // Add 50px to the x and y coordinates of the duplicated asset
        duplicatedAsset.x += 500;
        duplicatedAsset.y += 500;

        this.setState({
            assets: [...this.state.assets, duplicatedAsset],
        });

        // Update handleRightClick function
        handleRightClick = (event) => {
            event.preventDefault();
            // Set the position of the context menu
            const contextMenuPosition = { top: event.clientY, left: event.clientX };
            // Set handlers for menu items
            const contextMenuActions = {
                duplicate: () => {
                    this.state.contextMenuActions.duplicate();
                    this.setState({ contextMenuVisible: false });
                },
                delete: () => {
                    this.state.contextMenuActions.delete();
                    this.setState({ contextMenuVisible: false });
                }
            };
            this.setState({ contextMenuVisible: true, contextMenuPosition, contextMenuActions });
        };
    }


    handleStageClick = (e) => {
        // Deselect the asset if clicked on empty area
        if (e.target === e.target.getStage()) {
            this.setState({
                assets: this.state.assets.map((asset) => ({ ...asset, selected: false })),
            });
        }
    };

    handleModalOpen = () => {
        this.setState({ modalOpen: true });
    };

    handleModalClose = () => {
        this.setState({ modalOpen: false });
        this.fetchMemorial();

    };

    handleModal2Open = () => {
        this.setState({ modal2Open: true });
    };

    handleModal2Close = () => {
        this.setState({ modal2Open: false });
        this.fetchMemorial();

    };



    render() {

        const { loading, stageScale, stageWidth, stageHeight } = this.state;

        // Add the CSS here
        const styles = `
  #context-menu div:hover {
      background-color: #f2f2f2;
      cursor: pointer;
  }

  .categories {
    display: flex;
    justify-content: space-between;
  }
  
  .asset-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .asset-item {
    width: 100px;
    height: 100px;
  }

  .canvas-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (9/16 * 100%) */
}

.canvas-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

`;

        const backgroundAspectRatio = 16 / 9; // Desired aspect ratio
        const { contextMenuVisible, contextMenuPosition, contextMenuActions } = this.state;
        const { memorial } = this.state; // Access memorial from the state
        return (
            <div>
                <Box sx={{ padding: 3 }}>
                    <div>

                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h1 style={{ color: "white" }}>Memorial of {this.state.deceased_name} {this.state.deceased_nickname} {this.state.deceased_surname}</h1>
                            <h2 style={{ color: "white" }}>{this.state.deceased_date_of_birth} - {this.state.deceased_date_of_death}</h2>


                            <div className="canvas-container" ref={this.containerRef} style={{ width: '71vw', height: '83.8vh', border: '1px solid black', borderRadius: '10px', overflow: 'hidden', position: 'relative', zIndex: 0 }}>

                                {loading && (
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.44)' }}>
                                        <h3 style={{ color: 'white', fontFamily: 'Verdana' }}>Loading Memorial...</h3><br /><br /><br />
                                        <InfinitySpin color="#3aaff2" height={200} width={200} />
                                    </div>
                                )}

                                <Stage
                                    width={stageWidth}
                                    height={stageHeight}
                                    scaleX={stageScale}
                                    scaleY={stageScale}
                                    draggable
                                    dragBoundFunc={(pos) => {
                                        const { maxTranslateX, maxTranslateY } = this.state;
                                        let newX = pos.x;
                                        let newY = pos.y;

                                        // Apply constraints
                                        if (pos.x > 0) {
                                            newX = 0;
                                        } else if (pos.x < maxTranslateX) {
                                            newX = maxTranslateX;
                                        }

                                        if (pos.y > 0) {
                                            newY = 0;
                                        } else if (pos.y < maxTranslateY) {
                                            newY = maxTranslateY;
                                        }

                                        return { x: newX, y: newY };
                                    }}
                                    ref={this.stageRef}
                                    onClick={this.handleStageClick}
                                >
                                    <Layer>
                                        {memorial && (
                                            <>
                                                <StaticImage src={memorial.backgroundImage} />
                                                {memorial.assets.map((asset, index) => {
                                                    let Component;
                                                    if (asset.type === 'text') {
                                                        Component = CanvasText;
                                                    } else if (asset.name.endsWith('.gif')) {
                                                        Component = Dove;
                                                    } else {
                                                        Component = StaticImage;
                                                    }

                                                    return (
                                                        <Component
                                                            key={index}
                                                            src={asset.name} // Use asset.name instead of asset.src
                                                            position={{ x: asset.x, y: asset.y }} // Use asset.x and asset.y for position
                                                            size={{ width: asset.width, height: asset.height }} // Use asset.width and asset.height for size
                                                            {...(asset.type === 'text' && { // Spread text properties for CanvasText component
                                                                text: asset.text,
                                                                fontSize: asset.fontSize,
                                                                fontFamily: asset.fontFamily,
                                                                fontStyle: asset.fontStyle,
                                                                textDecoration: asset.textDecoration,
                                                                fill: asset.fill,
                                                                x: asset.x,
                                                                y: asset.y,
                                                                width: asset.width,
                                                                height: asset.height,
                                                                scaleX: asset.scaleX,
                                                                scaleY: asset.scaleY


                                                            })}
                                                        />
                                                    );
                                                })}
                                            </>
                                        )}

                                    </Layer>
                                </Stage>

                            </div>
                            {/* Modal for Edit_memorial component */}
                            <Modal
                                open={this.state.modalOpen}
                                onClose={this.handleModalClose}
                                aria-labelledby="edit-memorial-modal"
                                aria-describedby="edit-memorial-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '90%',
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Edit_memorial_modal />
                                </Box>
                            </Modal>



                            <Modal
                                open={this.state.modal2Open}
                                onClose={this.handleModal2Close}
                                aria-labelledby="edit-memorial-modal"
                                aria-describedby="edit-memorial-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '60%',
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Edit_memorial_info_modal />
                                </Box>
                            </Modal>
                        </div>

                    </div>
                </Box>
            </div>
        );
    }
}

export default Memorial_viewer_public;