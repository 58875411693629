import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ShareModal from './ShareModal';

const ShareButton_public = ({ memorialId }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentMemorial, setCurrentMemorial] = useState(null);
    const baseUrl = window.location.origin; // Use current origin as base URL
    const [hovered, setHovered] = useState(false); // Hover state

    const handleOpenModal = () => {
        setCurrentMemorial(memorialId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setCurrentMemorial(null);
        setOpenModal(false);
    };

    const shareUrl = currentMemorial ? `${baseUrl}/View_memorial/${currentMemorial}` : '';

    return (
        <>
            <Button
                variant="contained"
                onClick={handleOpenModal}
                onMouseEnter={() => setHovered(true)} // Expand on hover
                onMouseLeave={() => setHovered(false)} // Shrink when not hovering
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    width: hovered ? '50%' : '50px', // Square when not hovered, expands on hover
                    height: '50px', // Fixed height to keep it square
                    transition: 'width 0.3s ease', // Smooth width transition
                    overflow: 'hidden', // Hide text when button is square
                    display: 'flex', // Align icon and text
                    alignItems: 'center', // Center content vertically
                    justifyContent: hovered ? 'space-between' : 'center', // Center icon when square, space-between when expanded
                    padding: hovered ? '0 16px' : '0', // Add padding when expanded
                    cursor: 'pointer', // Show pointer cursor
                }}
            >
                {/* Icon */}
                <ShareIcon style={{ color: 'black', width: '24px', height: '24px' }} />

                {/* Show text only on hover */}
                {hovered && (
                    <Box ml={1}>Share Memorial</Box>
                )}
            </Button>

            {/* Share Modal */}
            <ShareModal
                open={openModal}
                handleClose={handleCloseModal}
                shareUrl={shareUrl}
            />
        </>
    );
};

export default ShareButton_public;
