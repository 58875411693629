import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { Card, IconButton, Modal, Box, CardHeader } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Media from './Media';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateIcon from '@mui/icons-material/Create';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Media_Player() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [mediaItems, setMediaItems] = useState([]);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [ownerId, setOwnerId] = useState(null);
    const [animateDirection, setAnimateDirection] = useState(null);

    const fetchMedia = async () => {
        try {
            const { data: memorialData, error } = await supabase
                .from('memorials')
                .select('media, deceased_name, deceased_nickname, deceased_surname, owner_id')
                .eq('memorial_Id', memorialId);

            if (error) {
                console.error('Error fetching media:', error.message);
                return;
            }

            if (memorialData && memorialData.length > 0) {
                const mediaFiles = memorialData[0]?.media || [];
                setMediaItems(mediaFiles);
                setOwnerId(memorialData[0].owner_id);
            }
        } catch (error) {
            console.error('Error fetching media:', error.message);
        }
    };

    useEffect(() => {
        fetchMedia();
    }, [memorialId]);

    // Automatic transition setup
    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedMediaIndex(prevIndex => (prevIndex < mediaItems.length - 1 ? prevIndex + 1 : 0));
            setAnimateDirection('left');
            setTimeout(() => {
                setAnimateDirection(null);
            }, 500);
        }, 10000);

        // Clear interval on component unmount or when mediaItems change
        return () => clearInterval(interval);
    }, [mediaItems]);

    const handleThumbnailClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleEditClick = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        fetchMedia();
    };

    const handlePrevThumbnail = () => {
        setSelectedMediaIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : mediaItems.length - 1));
        setAnimateDirection('right');
        setTimeout(() => {
            setAnimateDirection(null);
        }, 500);
    };

    const handleNextThumbnail = () => {
        setSelectedMediaIndex(prevIndex => (prevIndex < mediaItems.length - 1 ? prevIndex + 1 : 0));
        setAnimateDirection('left');
        setTimeout(() => {
            setAnimateDirection(null);
        }, 500);
    };

    const renderThumbnail = (item, index) => {
        const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'];
        const videoFormats = ['mp4', 'mov', 'avi', 'mkv', 'webm'];

        const fileExtension = item.url.split('.').pop().toLowerCase();

        if (imageFormats.includes(fileExtension)) {
            return (
                <img
                    key={index}
                    src={item.url}
                    alt={`media-${index}`}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        cursor: 'pointer',
                        transform: index === selectedMediaIndex ? 'translateX(0)' : index < selectedMediaIndex ? 'translateX(-100%)' : 'translateX(100%)',
                        transition: 'transform 0.5s ease-in-out'
                    }}
                    onClick={handleThumbnailClick}
                    onError={(e) => { e.target.src = 'fallback-image-url'; }} // Fallback image
                />
            );
        } else if (videoFormats.includes(fileExtension)) {
            return (
                <div
                    key={index}
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                        transform: index === selectedMediaIndex ? 'translateX(0)' : index < selectedMediaIndex ? 'translateX(-100%)' : 'translateX(100%)',
                        transition: 'transform 0.5s ease-in-out'
                    }}
                    onClick={handleThumbnailClick}
                >
                    <video
                        src={item.url}
                        alt={`media-${index}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            display: 'block',
                        }}
                        muted
                        loop
                        onError={(e) => { e.target.poster = 'fallback-video-poster-url'; }} // Fallback poster
                    />
                    <PlayCircleIcon
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: 56,
                            color: 'rgba(255, 255, 255, 0.8)',
                        }}
                    />
                </div>
            );
        }
        return null;
    };

    const galleryItems = mediaItems.map((item) => {
        const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'];
        const videoFormats = ['mp4', 'mov', 'avi', 'mkv', 'webm'];

        const fileExtension = item.url.split('.').pop().toLowerCase();

        if (videoFormats.includes(fileExtension)) {
            return {
                original: item.url,
                thumbnail: item.url,
                renderItem: () => (
                    <video controls autoPlay={false} style={{ width: '100%' }}>
                        <source src={item.url} type={`video/${fileExtension}`} />
                        Your browser does not support the video tag.
                    </video>
                ),
            };
        } else if (imageFormats.includes(fileExtension)) {
            return {
                original: item.url,
                thumbnail: item.url,
                renderItem: () => (
                    <img src={item.url} alt="" style={{ width: '100%' }} />
                ),
            };
        }
        return null;
    });

    return (
        <>
            <Card elevation={10} style={{ width: '100%', padding: '10px', marginTop: '10px', height: 'auto', overflow: 'auto', borderRadius: '10px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <CardHeader
                    title="Media Gallery"
                    action={
                        <IconButton
                            sx={{ position: 'absolute', top: '3px', right: '3px', backgroundColor: '#f0f0f0' }}
                            onClick={handleEditClick}
                        >
                            <CreateIcon />
                        </IconButton>
                    }
                    sx={{
                        padding: '2%', // Reduce padding
                        '& .MuiTypography-root': { // Target the Typography inside the CardHeader
                            fontSize: '1.3rem', // Reduce the font size
                        },
                        position: 'sticky',
                        top: '-10px',
                        bgcolor: '#fff',
                        zIndex: 1000,
                        borderRadius: '10px'
                    }}
                />
                <br />
                {mediaItems.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                        <IconButton onClick={handlePrevThumbnail} style={{ position: 'absolute', left: 0 }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <div style={{ width: '80%', height: '20vh', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                {mediaItems.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            transform:
                                                index === selectedMediaIndex
                                                    ? 'translateX(0)'
                                                    : index < selectedMediaIndex
                                                        ? 'translateX(-100%)'
                                                        : 'translateX(100%)',
                                            transition: 'transform 0.5s ease-in-out',
                                        }}
                                    >
                                        {renderThumbnail(item, index)}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <IconButton onClick={handleNextThumbnail} style={{ position: 'absolute', right: 0 }}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </div>
                )}

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    style={{ zIndex: 1300 }}
                >
                    <Box
                        position="absolute"
                        top="5%"
                        left="10%"
                        right="10%"
                        bottom="5%"
                        width="80%"
                        maxWidth="90%"
                        maxHeight="90%"
                        overflow="auto"
                        bgcolor="background.paper"
                        boxShadow={24}
                        p={5}
                        style={{ outline: 'none' }}
                    >
                        <IconButton
                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                            onClick={handleCloseModal}
                        >
                            <CloseIcon />
                        </IconButton>
                        {selectedMediaIndex !== null && (
                            <ImageGallery
                                items={galleryItems}
                                startIndex={selectedMediaIndex}
                                showThumbnails={false}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                renderItem={(item) => (
                                    <Box
                                        component="div"
                                        sx={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            img: {
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'contain',
                                            },
                                        }}
                                    >
                                        {item.renderItem()}
                                    </Box>
                                )}
                            />
                        )}
                    </Box>
                </Modal>
            </Card>

            <Modal
    open={isEditModalOpen}
    onClose={handleCloseEditModal}
    aria-labelledby="edit-voice-clips-modal"
    aria-describedby="modal containing voice clips editor"
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
<Modal
    open={isEditModalOpen}
    onClose={handleCloseEditModal}
    aria-labelledby="edit-voice-clips-modal"
    aria-describedby="modal containing voice clips editor"
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
    <Box
        sx={{
            outline: 'none',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: '100%', // Twice the original width
            maxWidth: '80vw', // Ensures it doesn't exceed 80% of viewport width
            maxHeight: '90vh', // Slightly reduced height
            borderRadius: '8px', // Higher border radius
            overflowY: 'hidden', // Prevent content from flowing outside
            position: 'relative', // For placing the close button
        }}
    >
        {/* Close Button */}
        <IconButton
            onClick={handleCloseEditModal}
            sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
                padding: '2px'
            }}
        >
            <CloseIcon />
        </IconButton>

        <Box
            sx={{
                p: 2,
                maxHeight: 'calc(90vh - 64px)', // Adjust for the close button's height and padding
                overflowY: 'auto', // Allow vertical scrolling
                paddingRight: '8px', // Space for scrollbar to avoid clipping rounded corners
                borderRadius: '8px', // Match the outer Box's border radius
            }}
        >
            <Media />
        </Box>
    </Box>
</Modal>




</Modal>

        </>
    );
}

export default Media_Player;
