import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill stylesheet
import {
    Card,
    CardContent,
    CardActions,
    IconButton,
    Typography,
    Collapse,
    Modal,
    Box,
    CircularProgress,
    Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CreateIcon from '@mui/icons-material/Create';
import styled from 'styled-components';
import Edit_about_card from './Edit_about_card'; // Adjust the import path as needed

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const JustifiedText = styled(Typography)`
    text-align: justify;
`;

const ExpandIcon = styled(IconButton)`
    transition: transform 0.3s;
    ${({ expanded }) => expanded && 'transform: rotate(180deg);'}
`;

function About_card() {
    const [content, setContent] = useState('');
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchContent = async () => {
        setLoading(true);
        setError('');
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');

        if (userId && memorialId) {
            const { data, error } = await supabase
                .from('memorials')
                .select('about, deceased_name, deceased_surname, deceased_nickname') // Fetching the required fields
                .eq('memorial_Id', memorialId)
                .single();

            if (error) {
                console.error('Error fetching content:', error);
                setError('Error fetching content');
            } else if (data) {
                setContent(data.about || '');
                setDeceasedName(data.deceased_name || '');
                setDeceasedSurname(data.deceased_surname || '');
                setDeceasedNickname(data.deceased_nickname || '');
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        fetchContent();
    };

    const getPreviewText = (delta, wordLimit = 100) => {
        if (!delta || typeof delta !== 'object' || !Array.isArray(delta.ops)) {
            return '';
        }

        let plainText = '';
        delta.ops.forEach(op => {
            if (typeof op.insert === 'string') {
                plainText += op.insert;
            }
        });

        const words = plainText.trim().split(' ').slice(0, wordLimit);
        return words.join(' ') + (words.length >= wordLimit ? '...' : '');
    };

    const previewText = getPreviewText(content);

    return (
        <Card elevation={5} style={{ width: '100%', padding: '0', paddingLeft: '10px', paddingRight: '10px', position: 'relative' }}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Alert severity="error">{error}</Alert>
                </Box>
            ) : (
                <>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Obituary of {deceasedName} {deceasedSurname}
                        </Typography>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <ReactQuill
                                value={content}
                                readOnly={true}
                                theme={"bubble"} // Use the "bubble" theme for a simpler appearance
                            />
                        </Collapse>
                        {!expanded && (
                            <JustifiedText variant="body2">
                                {previewText}{" "}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleExpandClick}>
                                    Read more...
                                </span>
                            </JustifiedText>
                        )}
                    </CardContent>
                    <CardActions disableSpacing>
                        <ExpandIcon
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            expanded={expanded}
                        >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ExpandIcon>
                    </CardActions>

                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="edit-about-modal"
                        aria-describedby="edit-about-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '75%',
                                maxHeight: '95vh', // Set maxHeight to make content scrollable
                                overflowY: 'auto',  // Enable vertical scrolling
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 2,
                            }}
                        >
                            <Edit_about_card />
                        </Box>
                    </Modal>
                </>
            )}
        </Card>
    );
}

export default About_card;
