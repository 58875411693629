// File: CreateMemorial.js

import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Dialog, DialogTitle } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import required styles
import Navbar from './Navbar';
import Steps from './Steps';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function generateRandomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function CreateMemorial() {
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [dateOfDeath, setDateOfDeath] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const memorialId = generateRandomId(10);
        const userId = Cookies.get('userId');

        const { data, error } = await supabase
            .from('memorials')
            .insert([
                {
                    owner_id: userId,
                    deceased_name: deceasedName,
                    deceased_surname: deceasedSurname,
                    deceased_nickname: deceasedNickname,
                    deceased_date_of_birth: dateOfBirth ? dateOfBirth.toISOString().split('T')[0] : null,
                    deceased_date_of_death: dateOfDeath ? dateOfDeath.toISOString().split('T')[0] : null,
                    memorial_Id: memorialId,
                },
            ]);

        if (error) {
            console.error('Error inserting memorial:', error.message);
        } else {
            Cookies.set('memorial_Id', memorialId);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
                navigate('/About');
            }, 2000);
        }
    };

    return (
        <div>
            <Navbar />
            <div
                style={{
                    backgroundImage: `url('/images/background2.jpg')`,
                    backgroundSize: 'cover',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        mt: 1,
                        width: '75%', // Reduced width by 25%
                        margin: 'auto',
                        border: '1px solid black',
                        borderRadius: '5px',
                        p: 2,
                        bgcolor: 'rgba(255, 255, 255, 0.97)',
                        display: 'flex',
                        flexDirection: 'column', // Stack fields vertically
                        gap: 1, // Reduced spacing between fields
                    }}
                >
                    <Typography variant="h4" component="h2" gutterBottom>
                        Create New Memorial
                    </Typography>
                    <Steps activeStep={1} />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="deceasedName"
                        label="Deceased Name"
                        name="deceasedName"
                        autoComplete="deceasedName"
                        autoFocus
                        value={deceasedName}
                        onChange={(event) => setDeceasedName(event.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="deceasedSurname"
                        label="Deceased Surname"
                        name="deceasedSurname"
                        autoComplete="deceasedSurname"
                        value={deceasedSurname}
                        onChange={(event) => setDeceasedSurname(event.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="deceasedNickname"
                        label="Deceased Nickname"
                        name="deceasedNickname"
                        autoComplete="deceasedNickname"
                        value={deceasedNickname}
                        onChange={(event) => setDeceasedNickname(event.target.value)}
                    />
                    <DatePicker
                        selected={dateOfBirth}
                        onChange={(date) => setDateOfBirth(date)}
                        dateFormat="yyyy-MM-dd"
                        customInput={
                            <TextField
                                fullWidth
                                required
                                margin="normal"
                                label="Date of Birth"
                            />
                        }
                    />
                    <DatePicker
                        selected={dateOfDeath}
                        onChange={(date) => setDateOfDeath(date)}
                        dateFormat="yyyy-MM-dd"
                        customInput={
                            <TextField
                                fullWidth
                                required
                                margin="normal"
                                label="Date of Death"
                            />
                        }
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }} // Slightly reduced space above the button
                    >
                        Save and Continue
                    </Button>
                    <Dialog autoHideDuration={2000} open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>Memorial Created Successfully</DialogTitle>
                    </Dialog>
                </Box>
            </div>
        </div>
    );
}

export default CreateMemorial;
