import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Donation_message = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);
  const handleDonate = () => {
    handleClose();
    navigate('/Donation');
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
          Love Offering
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
        Behind 4EVR.ORG.ZA is a team of dedicated developers and infrastructure. Your gift, no matter how small, will help us keep your loved one's memory alive 4EVR.
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleDonate}
          >
            Leave an offering
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleClose}
          >
            No thank you
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default Donation_message;
