import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
        maxHeight: '83.8vh', // Adjust the height as needed
        overflowY: 'scroll',
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        msOverflowStyle: 'none', // Hide scrollbar for IE/Edge
        '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
        },
        paddingRight: theme.spacing(2), // Add this line
    },
    paper: {
        padding: theme.spacing(1),
        margin: 'auto',
        maxWidth: 500,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column', // Change this line
        alignItems: 'center', // Change this line
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        transition: 'transform 0.5s',
        cursor: 'pointer',
        border: '1px solid #000', // Add this line
    },
    image: {
        width: 64,
        height: 64,
        borderRadius: '50%',
        marginRight: theme.spacing(2),
        objectFit: 'cover',
    },
}));

const Tributes = () => {
    const classes = useStyles();
    const [tributesData, setTributesData] = useState([
        {
            name: "Oliver Tambo",
            imageUrl: "https://images.theconversation.com/files/190606/original/file-20171017-30390-1bx309e.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=926&fit=clip",
            message: "Apartheid's nemesis, Ahmed Kathrada's resilience embodied a lifelong quest for justice. His legacy stands as a testament to an indomitable spirit that reshaped South Africa.",
            postTime: "1 minute ago"
        },
        {
            name: "Desmond Tutu",
            imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Archbishop-Tutu-medium.jpg/640px-Archbishop-Tutu-medium.jpg",
            message: "Beyond politics, Kathrada mentored with compassion, inspiring leaders committed to social justice. His enduring impact is a legacy of kindness in leadership.",
            postTime: "2 minutes ago"
        },
        {
            name: "Thabo Mbeki",
            imageUrl: "https://cdn.britannica.com/85/97385-004-0A389881/Thabo-Mbeki.jpg",
            message: "Post-apartheid, Kathrada tirelessly built bridges for unity. His vision transcended borders, fostering harmony and reconciliation—a legacy of coexistence.",
            postTime: "3 minutes ago"
        },
        {
            name: "Walter Sisulu",
            imageUrl: "https://alchetron.com/cdn/walter-sisulu-475e092b-4611-4759-8d4f-cc7c1f3262a-resize-750.jpeg",
            message: "A global icon for human rights, Kathrada's dedication extended beyond apartheid. In remembering him, we celebrate enduring values of dignity and equality.",
            postTime: "4 minutes ago"
        }
    ]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Simulating fetching new tributes data
            // You can replace this with actual data fetching logic
            const newTributesData = tributesData.slice(1).concat(tributesData[0]);
            setTributesData(newTributesData);
        }, 3000); // Adjust the interval as needed

        return () => clearInterval(intervalId);
    }, [tributesData]);

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                {tributesData.length > 0 && tributesData.map((tribute, index) => (
                    <Paper key={index} className={classes.paper}>
                        <img className={classes.image} alt={tribute.name} src={tribute.imageUrl} />
                        <div>
                            <Typography variant="subtitle1">{tribute.name}</Typography>
                            <Typography variant="body2">{tribute.message}</Typography>
                            <Typography variant="caption" color="textSecondary">{tribute.postTime}</Typography>
                        </div>
                    </Paper>
                ))}
            </div>
        </ThemeProvider>
    );
};

export default Tributes;
