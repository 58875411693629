import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { Card, CardHeader, Typography, Paper, IconButton, CardActions, Modal, Box, Button } from '@mui/material';
import AudioIcon from '@mui/icons-material/Audiotrack';
import VoiceClips from './Voice_clips'; // Assuming this is where VoiceClips component is located
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Voice_Player() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [voiceClips, setVoiceClips] = useState([]);
    const [currentClipIndex, setCurrentClipIndex] = useState(null);
    const audioRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [refreshPlayer, setRefreshPlayer] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        const fetchVoiceClips = async () => {
            const { data: memorialData, error: memorialError } = await supabase
                .from('memorials')
                .select('voice_clips')
                .eq('memorial_Id', memorialId);

            if (memorialError) {
                console.error('Error fetching voice clips:', memorialError.message);
                return;
            }

            if (memorialData && memorialData.length > 0) {
                const clips = memorialData[0]?.voice_clips || [];
                setVoiceClips(clips);
                if (clips.length > 0) {
                    setCurrentClipIndex(0);
                }
            }
        };

        fetchVoiceClips();
    }, [userId, memorialId, refreshPlayer]);

    const handleClipClick = (index) => {
        setCurrentClipIndex(index);
        if (audioRef.current) {
            audioRef.current.load();
            audioRef.current.play();
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setRefreshPlayer(!refreshPlayer);
    };

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Card elevation={10} style={{ width: '100%', padding: '10px', marginTop: '10px', height: 'auto', borderRadius: '10px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <CardHeader
                    title="Voice Clips"
                    action={
                        <IconButton
                            sx={{ position: 'absolute', top: '3px', right: '3px', backgroundColor: '#f0f0f0' }}
                            onClick={handleOpenModal}
                        >
                            <CreateIcon />
                        </IconButton>
                    }
                    sx={{
                        padding: '2%',
                        '& .MuiTypography-root': { fontSize: '1.3rem' },
                        position: 'sticky',
                        top: '-10px',
                        bgcolor: '#fff',
                        zIndex: 1000,
                        borderRadius: '10px'
                    }}
                />
                <br />
                {voiceClips.length === 0 ? (
                    <Button variant="contained" color="primary" onClick={handleOpenModal}>
                        Upload Voice Clips
                    </Button>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={scrollLeft}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <div ref={scrollRef} style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', scrollbarWidth: 'none', msOverflowStyle: 'none', flexGrow: 1 }}>
                            <div style={{ display: 'flex', width: 'max-content' }}>
                                {voiceClips.map((clip, index) => (
                                    <Paper
                                        key={index}
                                        elevation={currentClipIndex === index ? 4 : 0}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '10px',
                                            textAlign: 'center',
                                            border: currentClipIndex === index ? '2px solid #3f51b5' : 'none',
                                            margin: '0 5px',
                                            minWidth: '100px',
                                        }}
                                        onClick={() => handleClipClick(index)}
                                    >
                                        <AudioIcon style={{ fontSize: 20, color: '#3f51b5' }} />
                                        <Typography variant="body2" style={{ marginTop: '8px', textAlign: 'center', fontSize: '10px' }}>
                                            {clip.name.length > 20 ? `${clip.name.substring(0, 20)}...` : clip.name}
                                        </Typography>
                                    </Paper>
                                ))}
                            </div>
                        </div>
                        <IconButton onClick={scrollRight}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </div>
                )}
                {voiceClips.length > 0 && (
                    <CardActions disableSpacing style={{ justifyContent: 'center' }}>
                        <audio controls ref={audioRef} autoPlay={false} style={{ width: '100%' }}>
                            <source src={voiceClips[currentClipIndex]?.url} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </CardActions>
                )}
            </Card>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="edit-voice-clips-modal"
                aria-describedby="modal containing voice clips editor"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        outline: 'none',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: '100%',
                        maxWidth: '80vw',
                        maxHeight: '90vh',
                        borderRadius: '8px',
                        overflowY: 'hidden',
                        position: 'relative',
                    }}
                >
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'grey.500',
                            padding: '2px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box
                        sx={{
                            p: 2,
                            maxHeight: 'calc(90vh - 64px)',
                            overflowY: 'auto',
                            paddingRight: '8px',
                            borderRadius: '8px',
                        }}
                    >
                        <VoiceClips />
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default Voice_Player;
