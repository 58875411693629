import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
} from '@mui/material';
import Navbar_public from './Navbar_public';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back arrow icon

const Donation = () => {
  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    amount: '',
  });

  const [amountFocused, setAmountFocused] = useState(false); // State to track focus
  const navigate = useNavigate();  // Initialize useNavigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement payment processing logic here
    console.log('Payment Information:', formData);
  };

  // Function to handle back navigation
  const handleBack = () => {
    navigate(-1);  // Navigate to the previous page
  };

  return (
    <div>
      <Navbar_public />
      <Box
        sx={{
          backgroundImage: 'url(images/background6.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
        }}
      >
        <Box sx={{ backgroundColor: 'rgba(238, 238, 238, 0.37)', padding: 3, borderRadius: 2, width:'70%' }}>
          <Typography variant="h4" gutterBottom>
            Love Offering
          </Typography>
          <Typography variant="body1" paragraph>
            Behind 4EVR.ORG.ZA is a team of dedicated developers and infrastructure. Your gift, no matter how small, will help keep your loved one's memory alive and s/he will feel your love-offering in the after-life.
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name on Card"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Card Number"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Expiration Date (MM/YY)"
                  name="expirationDate"
                  value={formData.expirationDate}
                  onChange={handleChange}
                  fullWidth
                  required
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="CVV"
                  name="cvv"
                  value={formData.cvv}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="password"
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Donation Amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="number"
                  onFocus={() => setAmountFocused(true)} // Set focus on input
                  onBlur={() => {
                    if (formData.amount === '') setAmountFocused(false); // Reset focus if empty
                  }}
                  InputProps={{
                    startAdornment: amountFocused || formData.amount ? <span>$</span> : null, // Show dollar sign if focused or amount is not empty
                  }}
                  sx={{ backgroundColor: '#fff' }}
                />
              </Grid>
            </Grid>

            {/* Buttons in a row */}
            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 2 }}>
              {/* Back Button */}
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBack}
                  sx={{
                    backgroundColor: '#6a1b9a', // Solid purple color
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#9c4dcc', // Slightly lighter purple on hover
                    },
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  Go Back
                </Button>
              </Grid>

              {/* Submit Offering Button */}
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ backgroundColor: '#3f51b5', marginLeft: 2 }} // Adjust color and margin
                >
                  Submit Offering ❤️💸
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Donation;
