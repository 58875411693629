import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogTitle, IconButton, Avatar, Snackbar } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import Navbar from './Navbar';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Settings() {
    const [form, setForm] = useState({
        name: '',
        surname: '',
        email: '',
        phone_number: '',
        password: '',
        profile_picture: ''
    });
    const [open, setOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    // Fetch user data
    const fetchUserData = async () => {
        const userId = Cookies.get('userId');

        const { data, error } = await supabase
            .from('Users')
            .select('name, surname, email, phone_number, password, profile_picture')
            .eq('id', userId)
            .single();

        if (error) {
            console.error('Error fetching user data:', error);
            return;
        }

        setForm(data);
    };

    useEffect(() => {
        fetchUserData(); // Call fetchUserData on component mount
    }, []);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadProfilePicture = async () => {
        if (!selectedFile) return;

        const userId = Cookies.get('userId');
        const fileName = `${userId}/${selectedFile.name}`;

        const { data, error } = await supabase.storage
            .from('profile_images')
            .upload(fileName, selectedFile);

        if (error) {
            console.error('Error uploading profile picture:', error);
            return;
        }

        const { publicURL, error: urlError } = supabase.storage
            .from('profile_images')
            .getPublicUrl(fileName);

        if (urlError) {
            console.error('Error getting public URL:', urlError);
            return;
        }

        // Update profile picture URL in the Users table
        const { error: updateError } = await supabase
            .from('Users')
            .update({ profile_picture: publicURL })
            .eq('id', userId);

        if (updateError) {
            console.error('Error updating user profile picture:', updateError);
            return;
        }

        // Fetch updated user data after uploading
        await fetchUserData(); // Ensure fetchUserData is awaited
        setSnackOpen(true); // Show success message
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const userId = Cookies.get('userId');

        const { error } = await supabase
            .from('Users')
            .update({ ...form })
            .eq('id', userId);

        if (error) {
            console.error('Error updating user data:', error);
            return;
        }

        setOpen(true);
        setTimeout(() => setOpen(false), 2000); // Close the modal after 2 seconds
    };

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background5.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '65%', backgroundColor: 'rgba(255, 255, 255, 0.95)', margin: 'auto', border: '1px solid black', borderRadius: '10px', padding: '20px' }}>
               

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px', marginRight: '50%', marginLeft: '50%'}}>
                        <Avatar
                            src={form.profile_picture || ''}
                            alt={`${form.name.charAt(0)}${form.surname.charAt(0)}`}
                            sx={{ width: 56, height: 56, marginRight: 2 }}
                        >
                            {form.profile_picture ? null : `${form.name.charAt(0)}${form.surname.charAt(0)}`}
                        </Avatar>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="icon-button-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        {/* <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={uploadProfilePicture}>
                                <PhotoCamera />
                            </IconButton>
                        </label>*/}</div> 

                    <h1>Account Details</h1> 
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    </div>
                    <TextField fullWidth label="Name" value={form.name} onChange={handleChange} name="name" />
                    <br /><br />
                    <TextField fullWidth label="Surname" value={form.surname} onChange={handleChange} name="surname" />
                    <br /><br />
                    <TextField fullWidth label="Email Address" value={form.email} onChange={handleChange} name="email" />
                    <br /><br />
                    <TextField fullWidth label="Phone Number" value={form.phone_number} onChange={handleChange} name="phone_number" />
                    <br /><br />
                    <TextField fullWidth label="Password" value={form.password} onChange={handleChange} name="password" type="password" />
                    <br /><br />
                    <Button variant="contained" color="primary" type="submit">Save Settings</Button>
                </form>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Account Settings Saved Successfully</DialogTitle>
                </Dialog>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackOpen(false)}
                    message="Profile picture updated successfully!"
                />
            </div>
        </div>
    );
}

export default Settings;
