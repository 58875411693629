import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Dialog, DialogTitle } from '@mui/material';
import Navbar from './Navbar';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Edit_memorial_info_modal() {
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfDeath, setDateOfDeath] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchMemorialInfo() {
            const userId = Cookies.get('userId');
            const memorialId = Cookies.get('memorialId');
            if (userId && memorialId) {
                try {
                    const { data, error } = await supabase
                        .from('memorials')
                        .select('*')
                        .eq('owner_id', userId)
                        .eq('memorial_Id', memorialId)
                        .single();
                    if (error) {
                        throw error;
                    }
                    if (data) {
                        const { deceased_name, deceased_surname, deceased_nickname, deceased_date_of_birth, deceased_date_of_death } = data;
                        setDeceasedName(deceased_name || '');
                        setDeceasedSurname(deceased_surname || '');
                        setDeceasedNickname(deceased_nickname || '');
                        setDateOfBirth(deceased_date_of_birth || '');
                        setDateOfDeath(deceased_date_of_death || '');
                    }
                } catch (error) {
                    console.error('Error fetching memorial info:', error.message);
                }
            } else {
                console.error('Missing userId or memorialId cookies');
            }
        }
        fetchMemorialInfo();
    }, []);

    const handleSaveAndExit = async (event) => {
        event.preventDefault();
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');
        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({
                        deceased_name: deceasedName,
                        deceased_surname: deceasedSurname,
                        deceased_nickname: deceasedNickname,
                        deceased_date_of_birth: dateOfBirth,
                        deceased_date_of_death: dateOfDeath,
                    })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);
                if (error) {
                    throw error;
                }
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                    navigate('/Memorial_list');
                }, 2000);
            } catch (error) {
                console.error('Error updating memorial info:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    const handleSave = async (event) => {
        event.preventDefault();
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');
        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({
                        deceased_name: deceasedName,
                        deceased_surname: deceasedSurname,
                        deceased_nickname: deceasedNickname,
                        deceased_date_of_birth: dateOfBirth,
                        deceased_date_of_death: dateOfDeath,
                    })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);
                if (error) {
                    throw error;
                }
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            } catch (error) {
                console.error('Error updating memorial info:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');
        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({
                        deceased_name: deceasedName,
                        deceased_surname: deceasedSurname,
                        deceased_nickname: deceasedNickname,
                        deceased_date_of_birth: dateOfBirth,
                        deceased_date_of_death: dateOfDeath,
                    })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);
                if (error) {
                    throw error;
                }
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                    navigate('/Edit_About');
                }, 2000);
            } catch (error) {
                console.error('Error updating memorial info:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    return (
        <div>
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                    mt: 1,
                    width: '65%',
                    margin: 'auto',
                    border: '1px solid black',
                    borderRadius: '5px',
                    p: 2,
                    bgcolor: 'rgba(255, 255, 255, 0.97)',
                }}
            >
                <Typography variant="h4" component="h2" gutterBottom>
                    Edit Memorial Info
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="deceasedName"
                    label="Deceased Name"
                    name="deceasedName"
                    autoComplete="deceasedName"
                    autoFocus
                    value={deceasedName}
                    onChange={(event) => setDeceasedName(event.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="deceasedSurname"
                    label="Deceased Surname"
                    name="deceasedSurname"
                    autoComplete="deceasedSurname"
                    value={deceasedSurname}
                    onChange={(event) => setDeceasedSurname(event.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="deceasedNickname"
                    label="Deceased Nickname"
                    name="deceasedNickname"
                    autoComplete="deceasedNickname"
                    value={deceasedNickname}
                    onChange={(event) => setDeceasedNickname(event.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="dateOfBirth"
                    label="Date of Birth"
                    name="dateOfBirth"
                    autoComplete="dateOfBirth"
                    value={dateOfBirth}
                    onChange={(event) => setDateOfBirth(event.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="dateOfDeath"
                    label="Date of Death"
                    name="dateOfDeath"
                    autoComplete="dateOfDeath"
                    value={dateOfDeath}
                    onChange={(event) => setDateOfDeath(event.target.value)}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}> {/* Add flex container */}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ margin: '0 1rem' }}
                        onClick={handleSave} // Add onClick handler for Save
                    >
                        Save
                    </Button>

                </Box>
                <Dialog autoHideDuration={2000} open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Memorial Updated Successfully</DialogTitle>
                </Dialog>
            </Box>
        </div>

    );
}

export default Edit_memorial_info_modal;
