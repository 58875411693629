import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'; // For dropdown
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { jwtDecode } from 'jwt-decode';
import Navbar2 from './Navbar2';
import { MuiTelInput } from 'mui-tel-input'
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FacebookLogin from 'react-facebook-login'; // Import Facebook login package
import Checkbox from '@mui/material/Checkbox'; // Checkbox for Terms and Conditions
import FormControlLabel from '@mui/material/FormControlLabel'; // Label for the Checkbox
require('./facebook.css');


const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Registration({ onSignIn, setUserId }) {
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(''); // For rest of the phone number
    const [modalMessage, setModalMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [invalidFields, setInvalidFields] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false); // New state for the checkbox

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (newNumber) => {
        setPhoneNumber(newNumber)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const sendEmail = async (recipient, subject, message) => {
        const formData = new URLSearchParams();
        formData.append('to', recipient);
        formData.append('subject', subject);
        formData.append('message', message);

        try {
            const response = await fetch('https://forever.org.za/sendEmail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(),
            });

            const result = await response.json();
            if (result.message === "Email sent successfully!") {
                console.log('Email sent successfully');
            } else {
                console.log('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };


    useEffect(() => {
        const googleScript = document.createElement('script');
        googleScript.src = 'https://accounts.google.com/gsi/client';
        googleScript.async = true;
        googleScript.onload = () => {
            window.google.accounts.id.initialize({
                client_id: '44443408474-so40qd1pkdhdfsl790ncq9ivvrv55oqv.apps.googleusercontent.com',
                callback: handleGoogleSignIn,
            });
            window.google.accounts.id.renderButton(
                document.getElementById('googleSignUpButton'),
                { theme: 'outline', size: 'large' }
            );
        };
        document.body.appendChild(googleScript);
    }, []);

    const handleGoogleSignIn = async (response) => {
        const userObject = jwtDecode(response.credential);
        const { given_name, family_name, email, picture } = userObject;

        try {
            const { data, error } = await supabase
                .from('Users')
                .insert([
                    {
                        name: given_name,
                        surname: family_name,
                        email: email,
                        profile_picture: picture,
                        phone_number: '',
                        verified: true,
                    },
                ])
                .select();
            if (error) {
                throw error;
            }
            setUserId(data[0].id);

            setModalMessage('Signed up with Google successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            sendEmail(email, 'Welcome to Forever Memorials', 'hi ' + given_name + ' Welcome to Forever memorials, where the world comes to pay tribute to their loved ones.');
        } catch (error) {
            console.error('Error inserting data:', error.message);
            setModalMessage('Google sign-up failed, please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Handling Facebook sign-up
    const handleFacebookResponse = async (response) => {
        const { name, email, picture, id } = response;

        try {
            const { data, error } = await supabase
                .from('Users')
                .insert([
                    {
                        name: name.split(' ')[0],
                        surname: name.split(' ').slice(1).join(' '),
                        email: email,
                        profile_picture: picture.data.url,
                        phone_number: '',
                        verified: true,
                    },
                ])
                .select();
            if (error) {
                throw error;
            }
            setUserId(data[0].id);

            setModalMessage('Signed up with Facebook successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            sendEmail(email, 'Welcome to Forever Memorials', 'hi ' + name + ' Welcome to Forever memorials, where the world comes to pay tribute to their loved ones.');
        } catch (error) {
            console.error('Error inserting data:', error.message);
            setModalMessage('Facebook sign-up failed, please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSubmit = async () => {
        const invalidFields = [];
        const fullPhoneNumber = `${phoneNumber}`;

        if (!firstName) {
            invalidFields.push('firstName');
        }
        if (!surname) {
            invalidFields.push('surname');
        }
        if (!email || !isValidEmail(email)) {
            invalidFields.push('email');
        }
        if (!password) {
            invalidFields.push('password');
        }
        if (!phoneNumber || !isValidPhoneNumber(fullPhoneNumber)) {
            invalidFields.push('phoneNumber');
        }
        if (!agreeToTerms) {
            invalidFields.push('agreeToTerms'); // Check if the checkbox is checked
        }


        if (invalidFields.length > 0) {
            setInvalidFields(invalidFields);
            setModalMessage('Please fill out all required fields correctly.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } else {
            try {
                const { data, error } = await supabase
                    .from('Users')
                    .insert([
                        {
                            name: firstName,
                            surname: surname,
                            email: email,
                            password: password,
                            phone_number: fullPhoneNumber,
                            verified: false,
                        },
                    ])
                    .select(); // This will return the inserted row(s), including the id

                if (error) {
                    throw error;
                }

                // Assuming only one user is inserted, get the first record's ID
                setUserId(data[0].id);

                setModalMessage('Signed Up Successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                sendEmail(email, 'Welcome to Forever Memorials', 'hi ' + data[0].name + ' Welcome to Forever memorials, where the world comes to pay tribute to their loved ones.');
                // Optionally, do something with newUserId if needed

                // Clear form fields
                setFirstName('');
                setSurname('');
                setEmail('');
                setPassword('');
                setPhoneNumber('');
            }
            catch (error) {
                console.error('Error inserting data:', error.message);
                setModalMessage('Something went wrong, please try again...');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        }

        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    };

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const isValidPhoneNumber = (phoneNumber) => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        return parsedNumber ? parsedNumber.isValid() : false;
    };

    return (
        <div>
            <Navbar2 />
            <ThemeProvider theme={createTheme()}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(/images/background7.jpg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        zIndex: -1,
                    }}
                />
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <img
                            src="/images/lock.png"
                            alt="Lock Icon"
                            style={{ width: '50px', height: '50px', marginBottom: '10px' }}
                        />
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            sx={{
                                fontFamily: "'Kaushan Script', cursive !important",
                                color: 'white',
                                mt: 1,
                            }}
                        >
                            Sign In
                        </Typography>
                        <Box
                            sx={{
                                mt: 3,
                                padding: '20px',
                                borderRadius: '10px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        error={invalidFields.includes('firstName')}
                                        helperText={
                                            invalidFields.includes('firstName')
                                                ? 'First name is required'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="surname"
                                        label="Surname"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        error={invalidFields.includes('surname')}
                                        helperText={
                                            invalidFields.includes('surname')
                                                ? 'Surname is required'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        error={invalidFields.includes('email')}
                                        helperText={
                                            invalidFields.includes('email')
                                                ? 'Please enter a valid email address'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        error={invalidFields.includes('password')}
                                        helperText={
                                            invalidFields.includes('password')
                                                ? 'Password is required'
                                                : ''
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <MuiTelInput defaultCountry="ZA" value={phoneNumber} onChange={handleChange} fullWidth />
                                </Grid>
                                {/* Checkbox for Terms and Conditions */}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={agreeToTerms}
                                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Typography>
                                                I agree to the forever.org.za{' '}
                                                <Link href="https://forever.org.za/Terms_of_service" target="_blank" rel="noopener">
                                                    terms of service
                                                </Link>{' '}
                                                &{' '}
                                                <Link href="https://forever.org.za/Privacy_policy" target="_blank" rel="noopener">
                                                    privacy policy
                                                </Link>.
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#003365',
                                    color: 'white',
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#002750',
                                    },
                                }}
                            >
                                Sign Up
                            </Button>

                            <Typography>or</Typography>

                            {/* Google Sign Up Button */}
                            <center> <div id="googleSignUpButton"></div></center>

                            {/* Facebook Sign Up Button */}
                            <FacebookLogin
                                appId="981836593420700"
                                fields="name,email,picture"
                                callback={handleFacebookResponse}
                                cssClass="facebook-button"
                                icon="fa-facebook"
                                textButton="&nbsp;&nbsp;Sign Up with Facebook"
                            />
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="#" variant="body2" onClick={onSignIn}>
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{ width: '100%' }}
                    >
                        {modalMessage}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </div>
    );
}

export default Registration;
