import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from "./Header";
import ReactRoundedImage from "react-rounded-image"
import Ahmed from './ahmed.jpg'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tributes from './Tributes';
import Image_gallery from "./Image_gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import Player from "@madzadev/audio-player";
import "@madzadev/audio-player/dist/index.css";
import BackgroundAudio from './BackgroundAudio';
import Canvas2 from './Canvas2';
import Video_gallery from './Video_gallery';
import Login from './Login';
import Registration from './Registration';
import Navbar from './Navbar';
import View_about from './View_about';
import { Button } from '@mui/material';
import Messages from './Messages';

const tracks = [
    {
        url: "https://weblinked.co.za/in-memoriam/audio/1.mp3",
        title: "Interview With Ahmed Kathrada",
        tags: ["house"],
    },
    {
        url: "https://weblinked.co.za/in-memoriam/audio/2.mp3",
        title: "Ahmed Kathrada - I miss my brother",
        tags: ["dnb"],
    },
    {
        url: "https://weblinked.co.za/in-memoriam/audio/3.mp3",
        title: "Ahmed Kathrada - Rivonia Trial",
        tags: ["dubstep"],
    },
    {
        url: "https://weblinked.co.za/in-memoriam/audio/4.mp3",
        title: "Ahmed Kathrada In Conversation With Nelson Mandela",
        tags: ["dubstep"],
    },
];

const colors = {
    tagsBackground: "#3e32e4",
    tagsText: "#ffffff",
    tagsBackgroundHoverActive: "#6e65f1",
    tagsTextHoverActive: "#ffffff",
    searchBackground: "#c3c4c7",
    searchText: "#00000",
    searchPlaceHolder: "#575a77",
    playerBackground: "#c3c4c7",
    titleColor: "#00000",
    timeColor: "#00000",
    progressSlider: "#3e32e4",
    progressUsed: "#0a0a0a",
    progressLeft: "#ffffff",
    bufferLoaded: "#656566",
    volumeSlider: "#3e32e4",
    volumeUsed: "#0a0a0a",
    volumeLeft: "#ffffff",
    playlistBackground: "#c3c4c7",
    playlistText: "#00000",
    playlistBackgroundHoverActive: "#656566",
    playlistTextHoverActive: "#656566",
};




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Navbar />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Memorial" {...a11yProps(0)} />
                        <Tab label="About" {...a11yProps(1)} />
                        <Tab label="Photo Album" {...a11yProps(2)} />
                        <Tab label="Voice Clips" {...a11yProps(3)} />
                        <Tab label="Video Clips" {...a11yProps(4)} />
                        <Tab label="Message Board" {...a11yProps(5)} />
                        <Tab label="Help" {...a11yProps(6)} />
                        <Tab label="Logout" {...a11yProps(7)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0} sx={{ padding: '0 !important' }}>
                    <Canvas2 />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '100vh' }}
                    >
                        <View_about />
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Image_gallery />;
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Player
                        customColorScheme={colors}
                        trackList={tracks}
                        includeTags={false}
                        includeSearch={true}
                        showPlaylist={true}
                        sortTracks={true}
                        autoPlayNextTrack={true}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <Video_gallery />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <Messages />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={6}>
                    Help
                    <Registration />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={7}>
                    Logout
                    <Login />
                </CustomTabPanel>
            </Box>
        </div >
    );
}