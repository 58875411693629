import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar, Button, Typography, Box, Link } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


const View_memorial = () => {
    const { memorialId } = useParams();
    const [memorial, setMemorial] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMemorialData = async () => {
            const { data, error } = await supabase
                .from('memorials')
                .select('deceased_name, deceased_surname, deceased_date_of_birth, deceased_date_of_death, media')
                .eq('memorial_Id', memorialId)
                .single();

            if (error) {
                console.error('Error fetching memorial data:', error);
            } else {
                setMemorial(data);
            }
        };

        fetchMemorialData();
    }, [memorialId]);

    const findValidImage = (media) => {
        const validImageTypes = ['jpeg', 'jpg', 'png', 'webp'];
        for (let item of media) {
            if (validImageTypes.includes(item.type)) {
                return item.url;
            }
        }
        return null;
    };

    const handleViewMemorialClick = () => {
        navigate(`/Memorial_public/${memorialId}`);
    };

    if (!memorial) {
        return <Typography>Loading...</Typography>;
    }

    const { deceased_name, deceased_surname, deceased_date_of_birth, deceased_date_of_death, media } = memorial;
    const profileImage = findValidImage(media) || null;

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundImage: `url('/images/background1_watermarked.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: 'center',
                color: '#fff',
                padding: { xs: 2, sm: 3, md: 4 },
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingX: { xs: 2, sm: 3 },
                }}
            >
                <Avatar
                    src={profileImage}
                    alt={`${deceased_name} ${deceased_surname}`}
                    sx={{
                        width: { xs: 100, sm: 150 },
                        height: { xs: 100, sm: 150 },
                        marginBottom: 3,
                    }}
                >
                    {!profileImage && <AccountCircleIcon sx={{ fontSize: { xs: 80, sm: 150 } }} />}
                </Avatar>
                <Typography variant="h5" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' } }}>
                    Memorial of {deceased_name} {deceased_surname}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1.2rem' }, marginTop: 1 }}>
                    {deceased_date_of_birth} - {deceased_date_of_death}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    Published on{' '}
                    <Link href="https://forever.org.za/Home" color="inherit" underline="hover">
                        forever.org.za
                    </Link>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        marginTop: 4,
                        paddingX: { xs: 3, sm: 5 },
                        paddingY: 1.5,
                        fontSize: { xs: '0.8rem', sm: '1rem' },
                    }}
                    onClick={handleViewMemorialClick}
                >
                    View Memorial
                </Button>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '10%',
                    width: '100%',
                    textAlign: 'center',
                    padding: 2,
                    zIndex: 2,
                }}
            >
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                    <Link href="https://forever.org.za/home" color="white" underline="hover" sx={{ marginRight: 3 }}>
                        Home
                    </Link>
                    <Link href="https://forever.org.za/About_us" color="white" underline="hover" sx={{ marginRight: 3 }}>
                        About Us
                    </Link>
                    <Link href="https://forever.org.za/Contact" color="white" underline="hover">
                        Contact Us
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default View_memorial;
