import React, { useState } from 'react';
import { Button, Drawer, Typography, TextField, MenuItem } from '@mui/material';

const CustomTextEditor = ({ isOpen, onClose, onInsert }) => {
    const [content, setContent] = useState('');
    const [fontSize, setFontSize] = useState(16);
    const [fontFamily, setFontFamily] = useState('Arial');
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [fontColor, setFontColor] = useState('#000000');

    const handleInsert = () => {
        // Create formatted text object
        const formattedText = {
            text: content,
            fontSize: fontSize,
            fontFamily: fontFamily,
            isBold: isBold,
            isItalic: isItalic,
            isUnderline: isUnderline,
            fill: fontColor
        };
        onInsert(formattedText);
        onClose();
        setContent(''); // Clear the text area
    };

    return (

        <div style={{ width: '100%', padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
                Text Editor
            </Typography>
            <TextField
                label="Text"
                multiline
                rows={4}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                select
                label="Font Size"
                value={fontSize}
                onChange={(e) => setFontSize(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            >
                {[12, 14, 16, 18, 20, 25, 35, 45, 65, 85].map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                label="Font Family"
                value={fontFamily}
                onChange={(e) => setFontFamily(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            >
                {['Arial', 'Verdana', 'Helvetica', 'Times New Roman', 'Tahoma', 'Trebuchet', 'Georgia', 'Garamond', 'Courier New', 'Brush Script MT'].map((font) => (
                    <MenuItem key={font} value={font} style={{ fontFamily: font }}>
                        {font}
                    </MenuItem>
                ))}
            </TextField>
            <div style={{ marginTop: '10px' }}>
                <Button
                    variant="contained"
                    onClick={() => setIsBold(!isBold)}
                    style={{ fontWeight: isBold ? 'bold' : 'normal', marginRight: '5px', color: isBold ? '#1976d2' : '#ffffff', backgroundColor: isBold ? '#ffffff' : '#1976d2' }}
                >
                    Bold
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setIsItalic(!isItalic)}
                    style={{ fontStyle: isItalic ? 'italic' : 'normal', marginRight: '5px', color: isItalic ? '#1976d2' : '#ffffff', backgroundColor: isItalic ? '#ffffff' : '#1976d2' }}
                >
                    Italic
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setIsUnderline(!isUnderline)}
                    style={{ textDecoration: isUnderline ? 'underline' : 'none', color: isUnderline ? '#1976d2' : '#ffffff', backgroundColor: isUnderline ? '#ffffff' : '#1976d2' }}
                >
                    Underline
                </Button>
            </div>
            <TextField
                label="Font Color"
                type="color"
                value={fontColor}
                onChange={(e) => setFontColor(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleInsert} fullWidth style={{ marginTop: '10px' }}>
                Insert Text
            </Button>
        </div>

    );
};

export default CustomTextEditor;
