import React, { useState, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // import styles
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import Navbar from './Navbar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Edit_about_card() {
    const [open, setOpen] = useState(false);
    const quillRef = useRef(null); // Create a ref for storing the Quill instance
    const navigate = useNavigate(); // Initialize useNavigate
    const [initialContent, setInitialContent] = useState(null); // State to store initial content

    useEffect(() => {
        async function fetchInitialContent() {
            const userId = Cookies.get('userId');
            const memorialId = Cookies.get('memorialId');

            if (userId && memorialId) {
                try {
                    const { data, error } = await supabase
                        .from('memorials')
                        .select('about')
                        .eq('owner_id', userId)
                        .eq('memorial_Id', memorialId)
                        .single();

                    if (error) {
                        throw error;
                    }

                    if (data) {
                        setInitialContent(data.about);
                    }
                } catch (error) {
                    console.error('Error fetching initial content:', error.message);
                }
            } else {
                console.error('Missing userId or memorialId cookies');
            }
        }

        fetchInitialContent();
    }, []);

    useEffect(() => {
        if (!quillRef.current) {
            quillRef.current = new Quill('#editor', {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // headers
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // lists
                        [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
                        [{ 'direction': 'rtl' }], // text direction
                        [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean'], // remove formatting button
                        ['link', 'image', 'video'], // link and image, video
                    ]
                }
            }); // Instantiate Quill on the client side
        }

        if (initialContent !== null) {
            quillRef.current.setContents(initialContent);
        }
    }, [initialContent]);

    const handleSave = async () => {
        const contentRaw = quillRef.current.getContents();

        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');

        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({ about: contentRaw })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);

                if (error) {
                    throw error;
                }

                setOpen(true);
                console.log('About section saved successfully');
            } catch (error) {
                console.error('Error saving about section:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    const handleSaveAndExit = async () => {
        await handleSave(); // Call handleSave function to save data
        setOpen(true); // Open the dialog
        setTimeout(() => {
            setOpen(false); // Close the dialog after 2 seconds
            navigate('/Memorial_list'); // Redirect to Memorial_list
        }, 2000);
    };

    const handleSaveAndContinue = async () => {
        await handleSave(); // Call handleSave function to save data
        setOpen(true); // Open the dialog
        setTimeout(() => {
            setOpen(false); // Close the dialog after 2 seconds
            navigate('/Edit_memorial'); // Redirect to Memorial_list
        }, 2000);
    };

    return (

        <div style={{ alignItems: 'center', height: '100vh', width: '100%' }}>
            <center> <h1>About Section</h1></center>
            <br></br>
            <div id="toolbar" style={{ width: '100%', }} /> {/* Set toolbar width to 100% */}
            <div id="editor" style={{ width: '100%', border: '1px solid #ccc', padding: '10px', backgroundColor: 'rgba(255, 255, 255, 1)' }} />

            <br></br>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '10px' }}>
                <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
            </div>


            <Dialog autoHideDuration={2000} open={open} onClose={() => setOpen(false)}>
                <DialogTitle>About Text Updated Successfully</DialogTitle>
            </Dialog>
        </div>


    );
}

export default Edit_about_card;
