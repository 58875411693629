import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import { Card, CardContent, Typography, Grid, IconButton, CircularProgress, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);
const bucketName = 'memorial_assets'; // Define bucket name once

function Media() {
    const userId = Cookies.get('userId');
    const memorialId = Cookies.get('memorialId');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]); // Track files being uploaded

    useEffect(() => {
        if (userId && memorialId) {
            updateFilesInDirectory();
        }
    }, [userId, memorialId]);

    const onDrop = React.useCallback((acceptedFiles) => {
        acceptedFiles.forEach(file => handleFileUpload(file));
    }, [userId, memorialId]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*,video/*', // Accept only image and video files
        maxSize: 5000242880,
    });

    const handleFileUpload = async (file) => {
        if (!userId || !memorialId) {
            console.error('Missing userId or memorialId cookies');
            return;
        }

        const fileDetails = {
            name: file.name,
            type: file.type.split('/')[0],
            preview: URL.createObjectURL(file),
            progress: 0
        };

        setUploadingFiles(prev => [...prev, fileDetails]);

        const { data, error } = await supabase.storage
            .from(bucketName)
            .upload(`public/${userId}/${memorialId}/${file.name}`, file, {
                upsert: true,
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setUploadingFiles(prev => prev.map(f => f.name === file.name ? { ...f, progress } : f));
                }
            });

        if (error) {
            console.error('Error uploading file:', error.message);
            return;
        }

        console.log('File uploaded successfully:', data.Key);

        setUploadingFiles(prev => prev.filter(f => f.name !== file.name));
        await updateFilesInDirectory();
    };

    const handleFileDelete = async (fileName) => {
        const { data, error } = await supabase.storage
            .from(bucketName)
            .remove([`public/${userId}/${memorialId}/${fileName}`]);

        if (error) {
            console.error('Error deleting file:', error.message);
            return;
        }

        console.log('File deleted successfully:', data);
        await updateFilesInDirectory();
    };

    const updateFilesInDirectory = async () => {
        const { data: fileList, error: listError } = await supabase.storage
            .from(bucketName)
            .list(`public/${userId}/${memorialId}`);

        if (listError) {
            console.error('Error listing files:', listError.message);
            return;
        }

        const mediaObject = await Promise.all(fileList
            .filter(file => ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'avi', 'webp', 'avif'].includes(file.name.split('.').pop().toLowerCase()))
            .map(async file => {
                const fileType = file.name.split('.').pop().toLowerCase();
                let preview = '';

                if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif'].includes(fileType)) {
                    preview = `${supabaseUrl}/storage/v1/object/public/${bucketName}/public/${userId}/${memorialId}/${file.name}`;
                } else if (['mp4', 'avi'].includes(fileType)) {
                    // Generate video thumbnail
                    const videoUrl = `${supabaseUrl}/storage/v1/object/public/${bucketName}/public/${userId}/${memorialId}/${file.name}`;
                    preview = await generateVideoThumbnail(videoUrl);
                }

                return {
                    name: file.name,
                    url: `${supabaseUrl}/storage/v1/object/public/${bucketName}/public/${userId}/${memorialId}/${file.name}`,
                    type: fileType,
                    preview
                };
            })
        );

        setUploadedFiles(mediaObject);

        try {
            const { data, error } = await supabase
                .from('memorials')
                .update({ media: mediaObject })
                .eq('owner_id', userId)
                .eq('memorial_Id', memorialId);

            if (error) {
                throw error;
            }

            console.log('Media URLs updated successfully');
        } catch (error) {
            console.error('Error updating media URLs:', error.message);
        }
    };

    const generateVideoThumbnail = async (videoUrl) => {
        // Function to generate video thumbnail
        // This is a placeholder implementation. You might need a server-side solution or a third-party service to generate video thumbnails.
        return videoUrl; // For simplicity, returning the video URL as a placeholder
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ width: '85%', height: '85%', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
                <h2>Media Files</h2>
                <br />
                <div {...getRootProps()} style={{ border: '2px dashed black', padding: '20px', textAlign: 'center' }}>
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
                </div>
                <br />
                <Grid container spacing={2} justifyContent="center">
                    {uploadingFiles.map((file, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Card style={{ width: '100%', position: 'relative' }}>
                                <CardContent>
                                    {file.type === 'image' ? (
                                        <img src={file.preview} alt={file.name} style={{ width: '100%', height: 'auto' }} />
                                    ) : (
                                        <video src={file.preview} alt={file.name} style={{ width: '100%', height: 'auto' }} controls />
                                    )}
                                    <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{file.name}</Typography>
                                    <LinearProgress variant="determinate" value={file.progress} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <br />
                <Grid container spacing={2} justifyContent="center">
                    {uploadedFiles.map((file, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Card style={{
                                width: '100%',
                                paddingTop: '100%', // This ensures the card is square
                                position: 'relative' // This is needed for absolute positioning
                            }}>
                                <CardContent style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                                    <IconButton onClick={() => handleFileDelete(file.name)} style={{ position: 'absolute', right: 0, top: 0 }}>
                                        <DeleteIcon />
                                    </IconButton>
                                    {['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif'].includes(file.type) && <img src={file.preview} alt={file.name} style={{ width: '100%', height: 'auto' }} />}
                                    {['mp4', 'avi'].includes(file.type) && <video src={file.preview} alt={file.name} style={{ width: '100%', height: 'auto' }} controls />}
                                    <br/>
                                    <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{file.name}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
}

export default Media;
