import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, IconButton, Typography, Slider, Tooltip, Modal } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, VolumeOff, SkipNext, SkipPrevious } from '@mui/icons-material';
import AlbumIcon from '@mui/icons-material/Album';
import Music from './Music'; // Ensure this path is correct

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const fetchSongs = async (memorialId, setSongs) => {
    const { data: memorialData, error: memorialError } = await supabase
        .from('memorials')
        .select('music')
        .eq('memorial_Id', memorialId);

    if (memorialError) {
        console.error('Error fetching songs:', memorialError.message);
        return;
    }

    if (memorialData && memorialData.length > 0) {
        const musicFiles = memorialData[0]?.music || [];
        setSongs(musicFiles.map(song => song.url));
    }
};

const Audio_Player_public = ({ memorialId }) => {
    const theme = useTheme();
    const userId = Cookies.get('userId');
    const [songs, setSongs] = useState([]);
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(100);
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);
    const [progress, setProgress] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        fetchSongs(memorialId, setSongs);
    }, [userId, memorialId]);

    useEffect(() => {
        if (songs.length > 0 && audioRef.current) {
            audioRef.current.src = songs[currentSongIndex];
            audioRef.current.volume = volume / 100;
            audioRef.current.play().catch(error => console.error('Autoplay failed:', error));
            setIsPlaying(true);
        }
    }, [songs, currentSongIndex]);

    const handlePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleVolumeChange = (event, newValue) => {
        const validVolume = isNaN(newValue) ? 100 : newValue;
        setVolume(validVolume);
        if (audioRef.current) {
            audioRef.current.volume = validVolume / 100;
        }
    };

    const handleProgressChange = (event, newValue) => {
        const validProgress = isNaN(newValue) ? 0 : newValue;
        setProgress(validProgress);
        if (audioRef.current) {
            audioRef.current.currentTime = (validProgress / 100) * audioRef.current.duration;
        }
    };

    useEffect(() => {
        const updateProgress = () => {
            if (audioRef.current && audioRef.current.duration && !isNaN(audioRef.current.duration)) {
                setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', updateProgress);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', updateProgress);
            }
        };
    }, []);

    const handleNextSong = () => {
        const nextIndex = (currentSongIndex + 1) % songs.length;
        setCurrentSongIndex(nextIndex);
        setProgress(0);
        if (audioRef.current) {
            audioRef.current.src = songs[nextIndex];
            audioRef.current.play();
        }
        setIsPlaying(true);
    };

    const handlePreviousSong = () => {
        const prevIndex = (currentSongIndex - 1 + songs.length) % songs.length;
        setCurrentSongIndex(prevIndex);
        setProgress(0);
        if (audioRef.current) {
            audioRef.current.src = songs[prevIndex];
            audioRef.current.play();
        }
        setIsPlaying(true);
    };

    return (
        <>
            <Card sx={{ display: 'flex', marginTop: '5px', borderRadius: '5px', backgroundColor: 'rgba(255, 255, 255, 0.8)', minHeight: '80px' }}>
                <audio ref={audioRef} onEnded={handleNextSong} />
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto', padding: '4px' }}>
                    <CardContent sx={{ padding: '4px' }}>
                        <Typography component="div" variant="subtitle1">
                            Memorial Music
                        </Typography>
                        <Typography variant="caption" color="text.secondary" component="div">
                            {`Song ${songs.length > 0 ? currentSongIndex + 1 : 0} of ${songs.length}`}
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 0.5, pb: 0.5 }}>
                        <IconButton aria-label="previous" onClick={handlePreviousSong} sx={{ padding: '4px' }}>
                            {theme.direction === 'rtl' ? <SkipNext sx={{ fontSize: 20 }} /> : <SkipPrevious sx={{ fontSize: 20 }} />}
                        </IconButton>
                        <IconButton aria-label="play/pause" onClick={handlePlayPause} sx={{ padding: '4px' }}>
                            {isPlaying ? <Pause sx={{ height: 24, width: 24 }} /> : <PlayArrow sx={{ height: 24, width: 24 }} />}
                        </IconButton>
                        <IconButton aria-label="next" onClick={handleNextSong} sx={{ padding: '4px' }}>
                            {theme.direction === 'rtl' ? <SkipPrevious sx={{ fontSize: 20 }} /> : <SkipNext sx={{ fontSize: 20 }} />}
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 0.5, pr: 0.5, pb: 0.5 }}>
                        <Slider value={progress} onChange={handleProgressChange} aria-labelledby="continuous-slider" size="small" sx={{ mx: 0.5 }} />
                        <Tooltip title="Volume" arrow placement="top">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton onClick={() => setShowVolumeSlider(!showVolumeSlider)} sx={{ padding: '4px' }}>
                                    {volume === 0 ? <VolumeOff sx={{ fontSize: 20 }} /> : <VolumeUp sx={{ fontSize: 20 }} />}
                                </IconButton>
                                {showVolumeSlider && (
                                    <Slider
                                        value={volume}
                                        onChange={handleVolumeChange}
                                        aria-labelledby="continuous-slider"
                                        sx={{ width: 60, mx: 0.5 }}
                                        size="small"
                                    />
                                )}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
                    <AlbumIcon sx={{ fontSize: 32 }} />
                </Box>
            </Card>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="edit-voice-clips-modal"
                aria-describedby="modal containing voice clips editor"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ outline: 'none' }}>
                    <Music />
                </Box>
            </Modal>
        </>
    );
};

export default Audio_Player_public;
