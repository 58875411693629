import React, { useState, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import Navbar from './Navbar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import Steps from './Steps';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function About() {
    const [open, setOpen] = useState(false);
    const quillRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!quillRef.current) {
            quillRef.current = new Quill('#editor', {
                theme: 'snow',
                modules: {
                    toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video'],
                    ]
                }
            });
        }
    }, []);

    const handleSave = async () => {
        const contentRaw = quillRef.current.getContents();

        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorial_Id');

        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({ about: contentRaw })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);

                if (error) {
                    throw error;
                }

                setOpen(true);
                console.log('About section saved successfully');
            } catch (error) {
                console.error('Error saving about section:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background1.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', backgroundColor: 'rgba(255, 255, 255, 0.8)', flexDirection: 'column', alignItems: 'center', height: '100vh', borderRadius: '10px', margin: '50px', width: '100%' }}>
                    <br />
                    <h1>Obituary Section</h1>
                    <div style={{ width: '80%' }}> {/* Adjust the width here */}
                        <Steps activeStep={2} className="steps-custom" />
                    </div>
                    <br />
                    <div style={{ width: '95%', height: '75%', backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                        <div id="toolbar" style={{ width: '100%' }} />
                        <div id="editor" style={{ width: '100%', border: '1px solid #ccc', padding: '10px', backgroundColor: 'rgba(255, 255, 255, 1)' }} />
                    </div>
                    <br /><br /><br />
                    <Button variant="contained" color="primary" onClick={handleSave}>Save & Continue</Button><br />
                    <Dialog autoHideDuration={2000} open={open} onClose={() => {
                        setOpen(false);
                        setTimeout(() => {
                            navigate('/Design_memorial');
                        }, 2000);
                    }}>
                        <DialogTitle>About Text Saved Successfully</DialogTitle>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default About;
