import React from 'react';
import Home from './Home';
import About_us from './About_us';
import Contact from './Contact';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { makeStyles } from '@mui/styles';
import Navbar2 from './Navbar2';


function LandingPage() {

  return (
    <div>
      <Navbar2 />
      <Home />
      <About_us />
      <Contact />
    </div>
  );
}

export default LandingPage;
