import React from 'react';
import { Box, BottomNavigation, BottomNavigationAction, useMediaQuery } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ExtensionIcon from '@mui/icons-material/Extension';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '10vh',
    },
    content: {
        flexGrow: 1,
    },
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1300, // Increase the zIndex value as needed
        border: '1px solid #ccc', // Add border here
        // Adjust or remove padding if needed
        padding: theme.spacing(4.5),
    },
}));

const HorizontalTabs = ({ value2, handleChange2 }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

    return (
        <Box className={classes.root}>
            {isMobile && (
                <BottomNavigation
                    value={value2}
                    onChange={handleChange2}
                    showLabels
                    className={classes.bottomNav}
                >
                    <BottomNavigationAction
                        icon={<ExtensionIcon sx={{ color: '#4287f5' }} />}
                        label="ADD ELEMENTS"
                        sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '0.7rem' } }}
                    />
                    <BottomNavigationAction
                        icon={<TextFieldsIcon sx={{ color: '#4287f5' }} />}
                        label="ADD TEXT"
                        sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '0.7rem' } }}
                    />
                    <BottomNavigationAction
                        icon={<MusicNoteIcon sx={{ color: '#4287f5' }} />}
                        label="ADD MUSIC"
                        sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '0.7rem' } }}
                    />
                    <BottomNavigationAction
                        icon={<RecordVoiceOverIcon sx={{ color: '#4287f5' }} />}
                        label="ADD Voice Clips"
                        sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '0.7rem' } }}
                    />
                    <BottomNavigationAction
                        icon={<PermMediaIcon sx={{ color: '#4287f5' }} />}
                        label="ADD Photos / Videos"
                        sx={{ '& .MuiBottomNavigationAction-label': { fontSize: '0.7rem' } }}
                    />
                </BottomNavigation>
            )}
        </Box>
    );
};

export default HorizontalTabs;
