import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Stack, IconButton, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Navbar2 from './Navbar2';
import { createClient } from '@supabase/supabase-js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ShareIcon from '@mui/icons-material/Share';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import ShareModal from './ShareModal';
import Messages_public from './Messages_public';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(/images/hero.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    textAlign: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  hero: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
    paddingTop: '80px',
    padding: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '80vh',
      paddingTop: '100px',
    },
  },
  heroTitle: {
    fontSize: '6rem',
    fontWeight: 'bold',
    fontFamily: "'Kaushan Script', cursive !important",
    lineHeight: '1.2',
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '1.1',
    },
  },
  heroSubtitle: {
    fontSize: '1.5rem',
    transition: 'opacity 1s ease-in-out',
    opacity: 0,
    display: 'inline-block',
    fontFamily: 'Arial, sans-serif',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  subtitleVisible: {
    opacity: 1,
  },
  memorialSection: {
    padding: '30px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
  },
  memorialGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '10px',
    },
  },
  memorialHeading: {
    fontSize: '3rem',
    fontWeight: 'bold',
    fontFamily: "'Kaushan Script', cursive !important",
    color: 'black',
    marginBottom: '20px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
}));

const CustomCard = styled(Card)(({ theme }) => ({
  width: '300px',
  height: '450px',
  margin: '1rem',
  boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.4)',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    height: 'auto',
  },
}));

const CardContentWrapper = styled(CardContent)({
  flex: '1 0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '10px',
});

const ImageWrapper = styled(Box)({
  flex: '0 1 200px',
  overflow: 'hidden',
});

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ContentWrapper = styled(Box)({
  flex: '1 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

// Helper function to find the first valid image
const findFirstImage = (media) => {
  if (!media || media.length === 0) return null;
  const validImageExtensions = ['jpg', 'jpeg', 'png', 'webp'];
  const validImage = media.find((item) =>
    validImageExtensions.includes(item.type.toLowerCase()) && item.url
  );
  return validImage ? validImage.url : null;
};

function Home() {
  const classes = useStyles();
  const [memorials, setMemorials] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentShareUrl, setCurrentShareUrl] = useState('');
  const [currentMemorial, setCurrentMemorial] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showFirstSubtitle, setShowFirstSubtitle] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowFirstSubtitle((prev) => !prev);
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchMemorials = async () => {
      const { data, error } = await supabase
        .from('memorials')
        .select('*')
        .eq('published', true)
        .limit(6);

      if (error) {
        console.error('Error fetching memorials:', error.message);
      } else {
        setMemorials(data);
      }
    };

    fetchMemorials();
  }, []);

  const handleOpenModal = (memorial) => {
    setCurrentMemorial(memorial);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setCurrentMemorial(null);
    setOpenModal(false);
  };

  const handleShareClick = (memorialId) => {
    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/View_memorial/${memorialId}`;
    setCurrentShareUrl(shareUrl);
    setOpenShareModal(true);
  };

  return (
    <div className={classes.root}>
      <Navbar2 />
      <Box className={classes.hero}>
        <Typography variant="h1" className={classes.heroTitle}>
          Welcome to 4EVR
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.heroSubtitle} ${showFirstSubtitle ? classes.subtitleVisible : ''}`}
        >
          Where the whole world finally comes to rest
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.heroSubtitle} ${!showFirstSubtitle ? classes.subtitleVisible : ''}`}
        >
          We are sorry for your deep loss. 4EVR helps you immortalize your loved ones with beautiful resting places
          and music, and by preserving photographs, videos, and voice clips of them. You can share your memorial with
          close family and friends and ask them to leave a tribute.
        </Typography>
      </Box>

      <Box className={classes.memorialSection}>
        <Typography variant="h4" className={classes.memorialHeading}>
          Featured Memorials
        </Typography>
        <Box className={classes.memorialGrid}>
          {memorials.map((memorial) => (
            <CustomCard key={memorial.memorial_id}>
              <CardContentWrapper>
                <ContentWrapper>
                  <Typography variant="h6" gutterBottom>
                    {memorial.deceased_name} {memorial.deceased_surname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Date of Birth: {memorial.deceased_date_of_birth}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Date of Death: {memorial.deceased_date_of_death}
                  </Typography>
                </ContentWrapper>
                <ImageWrapper>
                  {memorial.media && memorial.media.length > 0 ? (
                    <Image
                      src={findFirstImage(memorial.media) || '/images/thumbnail.svg'}
                      alt="Media"
                    />
                  ) : (
                    <Image src="/images/thumbnail.svg" alt="Default Thumbnail" />
                  )}
                </ImageWrapper>
              </CardContentWrapper>
              <Stack direction="row" spacing={1} justifyContent="center" sx={{ padding: '10px' }}>
                <Button
                  variant="text"
                  onClick={() => handleOpenModal(memorial)}
                >
                  Learn More
                </Button>
                <IconButton onClick={() => handleShareClick(memorial.memorial_id)}>
                  <ShareIcon />
                </IconButton>
              </Stack>
            </CustomCard>
          ))}
        </Box>
      </Box>

      {openModal && currentMemorial && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '50%',
              height: '80%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 2,
              overflowY: 'auto',
            }}
          >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
  <Typography variant="h5" gutterBottom>
    {currentMemorial.deceased_name} {currentMemorial.deceased_surname}
  </Typography>
  <Typography variant="body1" paragraph>
    Born: {currentMemorial.deceased_date_of_birth} - Died: {currentMemorial.deceased_date_of_death}
  </Typography>
</Box>
            {currentMemorial.about && (
            <ReactQuill
            value={currentMemorial.about}
            readOnly={true}
            style={{ height: 'auto', width: '100%' }}
            modules={{ toolbar: false }}
          />
            )}

{currentMemorial.memorial_Id && (
          <Messages_public memorialId={currentMemorial.memorial_Id} />
)}

          </Box>
        </Modal>
      )}

      {openShareModal && currentShareUrl && (
        <ShareModal
          open={openShareModal}
          handleClose={() => setOpenShareModal(false)}
          shareUrl={currentShareUrl}
        />
      )}
    </div>
  );
}

export default Home;
