import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    logo: {
        height: '75px',
        position: 'relative',
        marginRight: theme.spacing(1),
    },
    menuButton: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24.2px',
        textAlign: 'left',
        color: 'white',
        marginLeft: theme.spacing(1),
        textTransform: 'capitalize',
    },
    drawer: {
        width: 240,
        padding: theme.spacing(2),
    },
    drawerButton: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'left',
        textTransform: 'capitalize',
    },
}));

function Navbar2() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleNavigation = (path) => () => {
        navigate(path);
        setDrawerOpen(false);
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'rgba(28, 58, 82, 0.5)',
                    boxShadow: 'none',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <img src="/images/logo3_white.png" alt="Logo" className={classes.logo} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            color="inherit"
                            className={classes.menuButton}
                            sx={{ color: 'white' }}
                            onClick={() => navigate('/Home')}
                        >
                            Home
                        </Button>
                        <Button
                            color="inherit"
                            className={classes.menuButton}
                            sx={{ color: 'white' }}
                            onClick={() => navigate('/About_us')}
                        >
                            About
                        </Button>
                        <Button
                            color="inherit"
                            className={classes.menuButton}
                            sx={{ color: 'white' }}
                            onClick={() => navigate('/Contact')}
                        >
                            Contact Us
                        </Button>
                        <Button
                            color="inherit"
                            className={classes.menuButton}
                            sx={{ color: 'white' }}
                            onClick={() => navigate('/authentication')}
                        >
                            Login/Register
                        </Button>
                    </Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                classes={{ paper: classes.drawer }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Button
                        color="inherit"
                        className={classes.drawerButton}
                        onClick={handleNavigation('/Home')}
                    >
                        Home
                    </Button>
                    <Button
                        color="inherit"
                        className={classes.drawerButton}
                        onClick={handleNavigation('/About_us')}
                    >
                        About
                    </Button>
                    <Button
                        color="inherit"
                        className={classes.drawerButton}
                        onClick={handleNavigation('/Contact')}
                    >
                        Contact Us
                    </Button>
                    <Button
                        color="inherit"
                        className={classes.drawerButton}
                        onClick={handleNavigation('/authentication')}
                    >
                        Login/Register
                    </Button>
                </Box>
            </Drawer>
        </>
    );
}

export default Navbar2;
