import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, ImageList, ImageListItem, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Asset_component = ({ selectAsset }) => {
    const [assetCategories, setAssetCategories] = useState({});
    const [allAssets, setAllAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const loadAssets = async () => {
            try {
                const response = await fetch("https://forever.org.za/asset_loader.php");
                
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json();
                setAssetCategories(data);

                // Convert categories to a flat list of assets and prepend the base URL
                const assets = Object.entries(data).flatMap(([category, assets]) =>
                    assets.map((asset) => ({ 
                        category, 
                        src: `https://forever.org.za${asset}` 
                    }))
                );
                setAllAssets(assets);
                setFilteredAssets(assets); // Initialize with all assets
            } catch (error) {
                console.error("Failed to load asset categories:", error);
            }
        };

        loadAssets();
    }, []);

    const filterByCategory = (category) => {
        setFilteredAssets(
            category === 'All' ? allAssets : allAssets.filter(asset => asset.category === category)
        );
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredAssets(allAssets.filter(asset =>
            asset.src.toLowerCase().includes(e.target.value.toLowerCase())
        ));
    };

    const handleAssetClick = (asset) => {
        selectAsset(asset.src, asset.category)();
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <Box sx={{ overflowX: 'auto', height: '100%', scrollbarWidth: 'none' }}>
            <TextField value={searchTerm} onChange={handleSearchChange} placeholder="Search elements..." variant="outlined" sx={{ borderColor: 'black', borderWidth: 5 }} fullWidth />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                <IconButton onClick={() => scrollContainerRef.current.scrollLeft -= 100} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    <ArrowBackIcon />
                </IconButton>
                <Box
                    ref={scrollContainerRef}
                    sx={{ display: 'flex', overflowX: 'auto', gap: '10px', p: 1, cursor: 'grab', scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseUp}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                >
                    <Button onClick={() => filterByCategory('All')} variant="contained" sx={{ minWidth: '170px', height: '30px' }} style={{ width: 'fit-content' }}>
                        Show All
                    </Button>
                    {Object.keys(assetCategories).map(category => (
                        <Button key={category} onClick={() => filterByCategory(category)} variant="contained" sx={{ minWidth: '170px', height: '30px' }} style={{ width: 'fit-content' }}>
                            {category}
                        </Button>
                    ))}
                </Box>
                <IconButton onClick={() => scrollContainerRef.current.scrollLeft += 100} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
                <ImageList variant="quilted" cols={3} gap={22}>
                    {filteredAssets.map(asset => (
                        <ImageListItem key={asset.src} sx={{
                            border: '2px solid black',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            '&:hover': {
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.11)',
                                }
                            }
                        }}>
                            <LazyLoadImage
                                src={asset.src}
                                alt={asset.category}
                                onClick={() => handleAssetClick(asset)}
                                effect="blur"
                                style={{
                                    transition: 'transform 0.3s ease-in-out',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </Box>
    )
}

export default Asset_component;
