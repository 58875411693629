import logo from './logo.svg';
import './App.css';
import Demo from './Demo';
import Authentication from './Authentication';
import Login from './Login';
import Registration from './Registration';
import Memorial_list from './Memorial_list';
import Design_memorial from './Design_memorial';
import Navbar from './Navbar';
import Auth_redirect from './Auth_redirect';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Create_memorial from './Create_memorial';
import Settings from './Settings.js';
import About from './About.js';
import Media from './Media.js';
import Image_gallery from './Image_gallery.js';
import Video_gallery from './Video_gallery.js';
import View_about from './View_about.js';
import Edit_about from './Edit_about.js';
import Edit_memorial_info from './Edit_memorial_info';
import Edit_media from './Edit_media';
import Edit_memorial from './Edit_memorial';
import My_memorials from './My_memorials';
import Canvas2 from './Canvas2';
import Memorial from './Memorial.js';
import Edit_memorial2 from './Edit_memorial2.js';
import Landing_page from './landing_page.js';
import Home from './Home.js';
import About_us from './About_us.js';
import Contact from './Contact.js';
import Memorial_public from './Memorial_public.js';
import View_memorial from './View_memorial.js';
import Donation from './Donation.js';
import Privacy_policy from './Privacy_policy.js';
import Terms_of_service from './Terms_of_service.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ViewMassMemorial from './ViewMassMemorial.js';
import MassMemorialFeed from './MassMemorialfeed.js';

function App() {
  return (
    <GoogleOAuthProvider clientId="44443408474-so40qd1pkdhdfsl790ncq9ivvrv55oqv.apps.googleusercontent.com">
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Auth_redirect" element={<Auth_redirect />} />
            <Route path="/Authentication" element={<Authentication />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/Create_memorial" element={<Create_memorial />} />
            <Route path="/About" element={<About />} />
            <Route path="/Media" element={<Media />} />
            <Route path="/View_about" element={<View_about />} />
            <Route path="/Image_gallery" element={<Image_gallery />} />
            <Route path="/Video_gallery" element={<Video_gallery />} />
            <Route path="/memorial_list" element={<Memorial_list />} />
            <Route path="/Design_memorial" element={<Design_memorial />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Demo" element={<Demo />} />
            <Route path="/Edit_about" element={<Edit_about />} />
            <Route path="/Edit_memorial_info" element={<Edit_memorial_info />} />
            <Route path="/Edit_media" element={<Edit_media />} />
            <Route path="/Edit_memorial" element={<Edit_memorial />} />
            <Route path="/My_memorials" element={<My_memorials />} />
            <Route path="/Canvas2" element={<Canvas2 />} />
            <Route path="/Memorial" element={<Memorial />} />
            <Route path="/Edit_memorial2" element={<Edit_memorial2 />} />
            <Route path="/Landing_page" element={<Landing_page />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/About_us" element={<About_us />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Donation" element={<Donation />} />
            <Route path="/Memorial_public/:memorialId" element={<Memorial_public />} />
            <Route path="/View_memorial/:memorialId" element={<View_memorial />} />
            <Route path="/Privacy_policy" element={<Privacy_policy />} />
            <Route path="/Terms_of_service" element={<Terms_of_service />} />
            <Route path="/View_mass_memorial/:id" element={<ViewMassMemorial />} />
            <Route path="/Mass_memorial_feed" element={<MassMemorialFeed />} />
          </Routes>
        </Router>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
