import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ExtensionIcon from '@mui/icons-material/Extension';
import TextFieldsIcon from '@mui/icons-material/TextFields';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const VerticalTabs = ({ value, handleChange }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                className={classes.tabs}
                style={{ width: '100%' }} // Ensure width is 100%
            >
                <Tab icon={<ExtensionIcon sx={{ color: '#4287f5' }} />} label="ADD ELEMENTS" />
                <Tab icon={<TextFieldsIcon sx={{ color: '#4287f5' }} />} label="ADD TEXT" />
                <Tab icon={<MusicNoteIcon sx={{ color: '#4287f5' }} />} label="ADD MUSIC" />
                <Tab icon={<RecordVoiceOverIcon sx={{ color: '#4287f5' }} />} label="ADD Voice Clips" />
                <Tab icon={<PermMediaIcon sx={{ color: '#4287f5' }} />} label="ADD Photos / Videos" />
            </Tabs>
        </Box>
    );
};

export default VerticalTabs;
