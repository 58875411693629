import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import 'react-quill/dist/quill.snow.css';
import { InfinitySpin } from 'react-loader-spinner';
import Modal from '@mui/material/Modal';
import ShareModal from './ShareModal';
import Pagination from '@mui/material/Pagination';

const CustomCard = styled(Card)({
    width: '300px',
    height: '400px',
    margin: '1rem',
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.4)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

const CardContentWrapper = styled(CardContent)({
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const ImageWrapper = styled(Box)({
    flex: '0 1 200px',
    overflow: 'hidden',
});

const Image = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

// Supabase client setup
const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function MassMemorialFeed() {
    const [massMemorials, setMassMemorials] = useState([]);
    const userId = Cookies.get('userId');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [currentShareUrl, setCurrentShareUrl] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMassMemorials, setFilteredMassMemorials] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 9;

    // Fetch mass memorials
    useEffect(() => {
        const fetchMassMemorials = async () => {
            setIsLoading(true);
            try {
                const { data, error } = await supabase
                    .from('mass_memorials')
                    .select('*')
                    .eq('published', true);

                if (error) throw error;
                if (data) setMassMemorials(data);
            } catch (error) {
                console.error('Error fetching mass memorials:', error.message);
            }
            setIsLoading(false);
        };

        if (userId) fetchMassMemorials();
    }, [userId]);

    // Apply search filter
    useEffect(() => {
        const filtered = massMemorials.filter(memorial => {
            const searchLower = searchTerm.toLowerCase();
            return (
                memorial.title.toLowerCase().includes(searchLower) ||
                memorial.description.toLowerCase().includes(searchLower)
            );
        });
        setFilteredMassMemorials(filtered);
    }, [searchTerm, massMemorials]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleShareClick = (memorialId) => {
        const baseUrl = window.location.origin;
        const shareUrl = `${baseUrl}/View_mass_memorial/${memorialId}`;
        setCurrentShareUrl(shareUrl);
        setOpenShareModal(true);
    };

    const handleViewClick = (memorialId) => {
        navigate(`/View_mass_memorial/${memorialId}`);
    };

    const handlePageChange = (event, value) => setCurrentPage(value);

    const paginatedMemorials = (filteredMassMemorials.length > 0 ? filteredMassMemorials : massMemorials)
        .slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background4.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                    width: { xs: '90%', sm: '85%' },
                    maxWidth: '1200px',
                    margin: '50px auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '10px',
                }}>
                    <Typography variant="h4" gutterBottom>
                        Mass Memorial Feed
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
    <TextField
        label="Search memorials..."
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ width: '100%', maxWidth: '400px', bgcolor: 'white' }} // Adjusted width
    />
</Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {isLoading ? (
                            <InfinitySpin width='200' color="#4fa94d" />
                        ) : (
                            paginatedMemorials.map((memorial) => (
                                <CustomCard key={memorial.id}>
                                    <ImageWrapper>
                                        {memorial.cover_image && (
                                            <Image src={memorial.cover_image} alt={memorial.title} />
                                        )}
                                    </ImageWrapper>
                                    <CardContentWrapper>
                                        <Typography variant="h6">{memorial.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {memorial.description}
                                        </Typography>
                                    </CardContentWrapper>
                                    <CardActions>
                                        <IconButton onClick={() => handleViewClick(memorial.id)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleShareClick(memorial.id)}>
                                            <ShareIcon />
                                        </IconButton>
                                        <IconButton>
                                            <FavoriteIcon />
                                        </IconButton>
                                    </CardActions>
                                </CustomCard>
                            ))
                        )}
                    </Box>
                    <Pagination
                        count={Math.ceil((filteredMassMemorials.length > 0 ? filteredMassMemorials.length : massMemorials.length) / pageSize)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ mt: 3 }}
                    />
                </Box>
            </div>
            <ShareModal open={openShareModal} onClose={() => setOpenShareModal(false)} shareUrl={currentShareUrl} />
        </div>
    );
}

export default MassMemorialFeed;
